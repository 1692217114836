import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";

import CreateComment from "../../components/CreateComment";
import CommentCard from "../../components/CommentCard";
import { isOwner } from "../../utils/auth";
import { useApp } from "../../contexts/App/Context";

const Comments = (props) => {
  const { me, onDeleteCommentInPost } = useApp()
  const { isPostPage, id, onDone, comments = [], user } = props;

  const isOwnerPost = isOwner(me, user)

  const handleDelete = async (commentId) => {
    try {
      await onDeleteCommentInPost(id, commentId)
    } catch (error) {
      throw error
    } finally {
      onDone()
    }
  }

  return (
    <Box>
      <Stack direction="column" spacing={2}>
        <CreateComment isPostPage={isPostPage} id={id} onDone={onDone} />
        {comments.map((comment) => (
          <CommentCard key={comment?._id} isOwnerPost={isOwnerPost} onDelete={handleDelete} {...comment} />
        ))}
      </Stack>
    </Box>
  );
};

export default Comments;
