import React, { useEffect } from "react";
import useWebSocket, { ReadyState } from "react-use-websocket";

import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Toolbar from "@mui/material/Toolbar";

import Conversations from "../../containers/Conversations";
import Conversation from "../../containers/Conversation";
import MessageForm from "../../containers/MessageForm";

import Navbar from "../../components/Navbar";

import { useAuth } from "../../contexts/Auth/Context";

const drawerWidth = 290;

const Messages = () => {
  const { token } = useAuth();
  const { readyState, ...props } = useWebSocket(
    // `${process.env.REACT_APP_WEBSOCKET_URL}/chat`,
    `${process.env.REACT_APP_WEBSOCKET_URL}`,
    {
      queryParams: { access_token: `Bearer ${token}` },
    }
  );

  useEffect(() => {
    const STATUS = ReadyState[readyState];
    console.log(`websocket-chat: ${STATUS}`);
  }, [readyState]);

  return (
    <Box>
      <Navbar />
      <Toolbar />
      <Box sx={{ display: "flex" }}>
        <Drawer
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            "& .MuiDrawer-paper": {
              width: drawerWidth,
              boxSizing: "border-box",
              top: 64,
              zIndex: 0,
              p: 2,
            },
          }}
          variant="permanent"
          anchor="left"
        >
          <Conversations />
        </Drawer>
        <Box
          sx={{
            height: "100vh",
            width: "100vw",
            p: 2,
          }}
        >
          <Conversation ws={props} />
          <Toolbar sx={{ mt: 2 }} />
          <MessageForm ws={props} />
        </Box>
      </Box>
    </Box>
  );
};

export default Messages;
