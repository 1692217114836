import axios from "axios";

const login = async (userCredential) => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}/auth/login`,
      userCredential
    );
    if (!res.data?.token) throw res.data;
    window.localStorage.setItem("token", res.data.token);
  } catch (error) {
    throw error;
  }
};

const facebook = async (userCredential) => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}/auth/facebook`,
      userCredential
    );
    if (!res.data?.token) throw res.data;
    window.localStorage.setItem("token", res.data.token);
  } catch (error) {
    throw error;
  }
};

const logout = async (token) => {
  try {
    await axios.post(`${process.env.REACT_APP_BACKEND_URL}/auth/logout`, null, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    window.localStorage.removeItem("token");
  } catch (error) {
    throw error;
  }
};

const register = async (userCredential) => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}/auth/register`,
      userCredential
    );
    if (!res.data?.userId) throw res.data;
  } catch (error) {
    throw error;
  }
};

export { login, logout, register, facebook };
