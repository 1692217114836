import React, { useState } from "react";

import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import LoadingButton from "@mui/lab/LoadingButton";
import Toolbar from "@mui/material/Toolbar";

import Navbar from "../../components/Navbar";

import { useApp } from "../../contexts/App/Context";
import { isVip } from "../../utils/auth";

export default function Packages() {
  const [paymentStatus, setPaymentStatus] = useState("");
  const { onUpdateMe, me } = useApp();

  const vip = isVip(me);

  const onCheckOut = async (e) => {
    e.preventDefault();
    try {
      if (!vip) {
        const roles = [...me.roles, "VIP"];
        const payload = { ...me, roles };
        await onUpdateMe(payload);
        setPaymentStatus("success");
      }
    } catch (error) {
      setPaymentStatus("fail");
      return error;
    }
  };

  return (
    <Box>
      <Navbar />
      <Toolbar />
      {paymentStatus === "success" && (
        <Alert severity="success">
          <AlertTitle>สำเร็จ</AlertTitle>
          สิทธิ์ของคุณคือ <strong>พื้นที่ส่วนตัว</strong>
        </Alert>
      )}
      {paymentStatus === "fail" && (
        <Alert severity="error">
          <AlertTitle>เกิดข้อผิดพลาด</AlertTitle>
          กรุณาแคปหน้าจอและติดต่อมาที่อีเมล{" "}
          <strong>cryptokeepto@gmail.com</strong>
        </Alert>
      )}

      <Container maxWidth="md" sx={{ mt: 10, mb: 5, textAlign: "center" }}>
        <Typography variant="h5" gutterBottom component="div">
          ยังมีสิ่งใหม่ๆ รอคุณอยู่
        </Typography>
        <Typography variant="h3" gutterBottom component="div">
          ลอง
          <Typography
            variant="h3"
            gutterBottom
            component="span"
            color="primary"
          >
            ปลดล็อก
          </Typography>
          สิ่งนั้นดูสิ
        </Typography>
      </Container>
      <Container maxWidth="md" sx={{ mb: 5 }}>
        <Stack
          justifyContent="center"
          alignItems="center"
          direction={{ xs: "column", sm: "row", md: "row" }}
          spacing={{ xs: 1, sm: 2, md: 4 }}
        >
          <Paper sx={{ pt: 4, pb: 4, pr: 3, pl: 3 }} elevation={0}>
            <Typography variant="h5" gutterBottom component="div">
              ฟรี
            </Typography>
            <Typography variant="caption" display="block" gutterBottom>
              สนุกไปกับฟังก์ชั่นพื้นฐาน ที่มาพร้อมให้กับคุณ
            </Typography>
            <Box>
              <ul>
                <li>ความยาวของวีดีโอสูงสุด 3 นาที</li>
                <li>โพสต์ทั้งหมดเป็นสาธารณะ</li>
                <li>มีผู้ติดตามได้สูงสุด 2,000 คน</li>
                <li>ไม่มีระบบจัดการผู้ติดตาม</li>
              </ul>
            </Box>
            <LoadingButton
              type="button"
              color="primary"
              fullWidth
              variant="outlined"
              disableRipple
            >
              กำลังใช้งานอยู่
            </LoadingButton>
          </Paper>
          <Paper sx={{ pt: 4, pb: 4, pr: 3, pl: 3 }} elevation={7}>
            <Typography variant="h5" gutterBottom component="div">
              พื้นที่ส่วนตัว
            </Typography>
            <Typography variant="caption" display="block" gutterBottom>
              เพิ่มประสบการณ์อิสระ ที่ไม่เหมือนใคร
            </Typography>
            <Box>
              <ul>
                <li>ความยาวของวีดีโอสูงสุด 1 ชั่วโมง</li>
                <li>โพสต์ทั้งหมดเป็นส่วนตัวและเห็นได้เฉพาะผู้ติดตาม</li>
                <li>มีผู้ติดตามได้ไม่จำกัด</li>
                <li>มีระบบจัดการผู้ติดตาม</li>
              </ul>
            </Box>
            {vip ? (
              <LoadingButton
                type="button"
                color="primary"
                fullWidth
                variant="outlined"
                disableRipple
              >
                กำลังใช้งานอยู่
              </LoadingButton>
            ) : (
              <LoadingButton
                type="button"
                color="success"
                fullWidth
                variant="contained"
                onClick={onCheckOut}
              >
                500 บาท / เดือน
              </LoadingButton>
            )}
          </Paper>
        </Stack>
      </Container>
    </Box>
  );
}
