import { useEffect, useCallback, useState } from "react";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Skeleton from "@mui/material/Skeleton";

import CreatePostCard from "../../components/CreatePostCard";
import PostCard from "../../components/PostCard";
import SharePostCard from "../../components/SharePostCard";
import Modal from "../../components/Modal";

import { useApp } from "../../contexts/App/Context";

const Timeline = () => {
  const { timeline, onGetTimeline, onGetTimelineCount } = useApp();
  const [isCreatePost, setCreatePost] = useState(false);
  const [total, setTotal] = useState(0);
  const [observedEl, setObservedEl] = useState(null);

  const isLoadMore = total > timeline.length;

  const init = useCallback(async () => {
    try {
      await onGetTimeline({ limit: 5, skip: 0 });
      const total = await onGetTimelineCount();
      setTotal(total);
    } catch (error) {
      throw error;
    }
  }, []);

  const handleModal = () => {
    setCreatePost(!isCreatePost);
  };

  useEffect(() => {
    const loadMore = async () => {
      try {
        await onGetTimeline({
          limit: 5,
          skip: isLoadMore ? timeline.length : total,
        });
      } catch (error) {
        throw error;
      }
    };

    const observer = new IntersectionObserver(
      async (items) => {
        if (items[0].isIntersecting) {
          await loadMore();
        }
      },
      {
        threshold: 1,
      }
    );
    if (observedEl) {
      observer.observe(observedEl);
    }

    return () => {
      if (observedEl) {
        observer.unobserve(observedEl);
      }
    };
  }, [observedEl, timeline, isLoadMore, total]);

  useEffect(() => {
    if (timeline.length === 0) {
      init();
    }
  }, []);

  return (
    <Container maxWidth="md">
      <Box sx={{ p: 2 }}>
        <CreatePostCard readOnly onToggleModal={handleModal} />
        <Modal isOpen={isCreatePost} onToggleModal={handleModal}>
          {isCreatePost && (
            <CreatePostCard onDone={init} onToggleModal={handleModal} />
          )}
        </Modal>
        <Box sx={{ mt: 3, mb: 3 }} />
        <Stack spacing={2}>
          {timeline.map((post) => {
            if (post?.shareId) {
              return <SharePostCard key={post._id} onDone={init} {...post} />;
            }
            return <PostCard key={post._id} onDone={init} {...post} />;
          })}
          {isLoadMore && <Skeleton ref={setObservedEl} />}
        </Stack>
      </Box>
    </Container>
  );
};

export default Timeline;
