import { useEffect } from "react";
import ImageListMui from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import CardMedia from "@mui/material/CardMedia";

const gridNumber = (files) => {
  let number = 3;
  if (files.length === 1) number = 1;
  if (files.length === 2) number = 2;
  return number;
};

const TAG = {
  video: "video",
  image: "img",
};

const getElementByMime = (mimeType) => {
  const type = mimeType.split("/")[0];
  return TAG[type];
};

const PreviewFilesList = ({ files }) => {
  useEffect(() => {
    return () => {
      files.forEach((file) => URL.revokeObjectURL(file.preview));
    };
  }, [files]);

  return (
    <ImageListMui
      sx={{ width: "100%", height: 350, margin: 0 }}
      cols={gridNumber(files)}
      gap={5}
    >
      {files.map((file, i) => {
        const element = getElementByMime(file.type);
        return (
          <ImageListItem key={i}>
            <CardMedia
              component={element}
              controls={element === "video"}
              image={file.preview}
              src={file.preview}
              title={file.name}
            />
          </ImageListItem>
        );
      })}
    </ImageListMui>
  );
};

export default PreviewFilesList;
