import React, { useEffect } from "react";
import { useForm } from "react-hook-form";

import { useTheme } from "@mui/material/styles";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import useMediaQuery from "@mui/material/useMediaQuery";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import LoadingButton from "@mui/lab/LoadingButton";
import Grid from "@mui/material/Grid";

import Avatar from "../Avatar";
import Background from "../Background";

import { useApp } from "../../contexts/App/Context";

const CreatePageDialog = ({ isOpen, handleModal }) => {
  const { isLoading, onCreatePage, onGetPages } = useApp();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
    reset,
  } = useForm();

  useEffect(() => {
    return () => {
      reset();
    };
  }, []);

  const onSubmit = async (variables) => {
    try {
      await onCreatePage(variables);
      await onGetPages();
    } catch (error) {
      throw error;
    } finally {
      handleModal();
    }
  };

  return (
    <Dialog
      open={isOpen}
      onClose={handleModal}
      fullScreen={fullScreen}
      aria-labelledby="create-page-dialog"
    >
      <Box component="form" noValidate onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle id="create-page-title">สร้างเพจ</DialogTitle>
        <DialogContent>
          <Grid item xs={12} sm={12} md={12}>
            <Box sx={{ position: "relative" }}>
              <Background
                editable
                alt="cover-picture-background"
                src={watch("coverPicture")}
                minWidth={{ md: 550 }}
                maxHeight={{ xs: 200, md: 220 }}
                onChange={(url) => setValue("coverPicture", url)}
              />
              <Box
                sx={{
                  position: "absolute",
                  top: { xs: 135, md: 145 },
                  left: "50%",
                  transform: "translateX(-50%)",
                  textAlign: "center",
                }}
              >
                <Avatar
                  width={{ xs: 110, md: 130 }}
                  height={{ xs: 110, md: 130 }}
                  src={watch("pagePicture")}
                  onChange={(url) => setValue("pagePicture", url)}
                />
              </Box>
            </Box>
            <Box sx={{ height: 75 }} />
            <Box>
              <TextField
                id="title"
                label="ชื่อเพจ"
                variant="standard"
                required
                disabled={isLoading}
                fullWidth
                error={Boolean(errors.title)}
                helperText={errors.title?.message}
                {...register("title", {
                  required: "ป้อนชื่อเพจ",
                })}
              />
            </Box>
            <Box>
              <TextField
                multiline
                rows={2}
                variant="standard"
                margin="normal"
                fullWidth
                id="description"
                label="คำอธิบาย"
                disabled={isLoading}
                error={Boolean(errors.description)}
                helperText={errors.description?.message}
                {...register("description")}
              />
            </Box>
          </Grid>
        </DialogContent>
        <DialogActions>
          <LoadingButton onClick={handleModal}>ยกเลิก</LoadingButton>
          <LoadingButton variant="contained" loading={isLoading} type="submit">
            สร้าง
          </LoadingButton>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

export default CreatePageDialog;
