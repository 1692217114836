import React, { useState, useEffect, useCallback } from "react";

import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import FavoriteOutlinedIcon from "@mui/icons-material/FavoriteOutlined";
import ModeCommentOutlinedIcon from "@mui/icons-material/ModeCommentOutlined";
import ModeCommentIcon from "@mui/icons-material/ModeComment";
import ShareOutlinedIcon from "@mui/icons-material/ShareOutlined";
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import MoreVertIcon from "@mui/icons-material/MoreVert";
import DeleteIcon from "@mui/icons-material/Delete";
import ListItemIcon from "@mui/material/ListItemIcon";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import Link from "@mui/material/Link";

import { useApp } from "../../contexts/App/Context";
import { format } from "../../utils/datetime";
import { isAdmin, isOwner } from "../../utils/auth";
import FilesList from "../FilesList";
import Comments from "../../containers/Comments";
import CreateSharePostCard from "../CreateSharePostCard";
import Modal from "../Modal";
import DialogBox from "../DialogBox";

const PostCard = (props) => {
  const {
    message = "",
    storage = [],
    likes = [],
    user,
    page,
    _id,
    createdAt,
    isShare,
    isPostPage = false,
  } = props;
  const {
    onLikePost,
    onLikePostPage,
    me,
    onDeletePost,
    onDeletePostPage,
    onGetCommentsInPost,
    onGetCommentsInPostPage,
  } = useApp();
  const [totalLike, setTotalLike] = useState(likes.length);
  const [isLiked, setIsLiked] = useState(false);
  const [isShowMessages, setIsShowMessages] = useState(false);
  const [isDeleteDialogBox, setIsDeleteDialogBox] = useState(false);
  const [isCreateSharePost, setCreateSharePost] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [comments, setComments] = useState(props?.comments || []);
  const isFiles = storage.length > 0;
  const isMessage = message.length > 0;
  const item = {
    _id: isPostPage ? page?.createdBy : user?._id,
  };

  useEffect(() => {
    const userLiked = likes.includes(me?._id);
    setIsLiked(userLiked);
  }, [me, likes]);

  const handleToggleDeleteDialogBox = () => {
    setIsDeleteDialogBox(!isDeleteDialogBox);
  };

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleShowMessages = () => {
    setIsShowMessages(!isShowMessages);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const likeHandler = async () => {
    try {
      setIsLiked(!isLiked);
      setTotalLike(isLiked ? totalLike - 1 : totalLike + 1);
      isPostPage ? await onLikePostPage(_id) : await onLikePost(_id);
    } catch (error) {
      throw error;
    }
  };

  const handleDeletePost = async () => {
    try {
      isPostPage ? await onDeletePostPage(_id) : await onDeletePost(_id);
      window.location.reload();
    } catch (error) {
      throw error;
    }
  };

  const handleCreateSharePostModal = () => {
    setCreateSharePost(!isCreateSharePost);
  };

  const init = useCallback(async () => {
    try {
      if (isPostPage) {
        const comments = await onGetCommentsInPostPage(_id);
        setComments(comments);
      } else {
        const comments = await onGetCommentsInPost(_id);
        setComments(comments);
      }
    } catch (error) {
      throw error;
    }
  }, [_id, onGetCommentsInPost, onGetCommentsInPostPage, isPostPage]);

  return (
    <Box>
      <Box>
        <Modal
          isOpen={isCreateSharePost}
          onToggleModal={handleCreateSharePostModal}
        >
          {isCreateSharePost && (
            <CreateSharePostCard
              onToggleModal={handleCreateSharePostModal}
              isPostPage={isPostPage}
              {...props}
            />
          )}
        </Modal>
      </Box>
      <Card variant={isShare ? "outlined" : "elevation"}>
        {!isShare && (
          <CardHeader
            avatar={
              <Avatar
                sx={{ width: 40, height: 40 }}
                alt={isPostPage ? page?.title : user?.username}
                src={isPostPage ? page?.pagePicture : user?.profilePicture}
                component={Link}
                underline="none"
                color="inherit"
                href={
                  isPostPage
                    ? `/#/pages/${page?._id}`
                    : `/#/profile/${user?._id}`
                }
              >
                {isPostPage
                  ? page?.title?.charAt(0).toUpperCase()
                  : user?.username?.charAt(0).toUpperCase()}
              </Avatar>
            }
            action={
              (isAdmin(me) || isOwner(me, item)) && (
                <IconButton
                  size="medium"
                  aria-label="settings"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleMenu}
                  color="inherit"
                >
                  <MoreVertIcon />
                </IconButton>
              )
            }
            title={isPostPage ? page?.title : user?.username}
            subheader={format(createdAt)}
          />
        )}
        <Menu
          sx={{ mt: "40px" }}
          id="menu-appbar"
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          keepMounted
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem
            onClick={() => {
              handleToggleDeleteDialogBox();
              handleClose();
            }}
          >
            <ListItemIcon>
              <DeleteIcon fontSize="small" />
            </ListItemIcon>
            <Typography variant="body2">ลบ</Typography>
            <DialogBox
              isOpen={isDeleteDialogBox}
              handleClose={handleToggleDeleteDialogBox}
              handleAgree={handleDeletePost}
              title="คุณต้องการลบโพสต์อันนี้ใช่ไหม ?"
              desc="โพสต์ของคุณจะถูกลบโดยถาวร และไม่สามารถกู้คืนได้ในภายหลัง"
            />
          </MenuItem>
        </Menu>
        <CardContent>
          {isMessage && (
            <Box>
              <Typography variant="body2">{message}</Typography>
            </Box>
          )}
          {isFiles && (
            <Box sx={{ mt: 2 }}>
              <FilesList files={storage} />
            </Box>
          )}
        </CardContent>
        {!isShare && (
          <Box>
            <CardActions sx={{ justifyContent: "space-between" }}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <IconButton size="small" onClick={likeHandler}>
                  {isLiked ? (
                    <FavoriteOutlinedIcon fontSize="medium" color="error" />
                  ) : (
                    <FavoriteBorderIcon fontSize="medium" />
                  )}
                </IconButton>
                <Typography>{totalLike}</Typography>
                <IconButton size="small" onClick={handleShowMessages}>
                  {isShowMessages ? (
                    <ModeCommentIcon fontSize="medium" />
                  ) : (
                    <ModeCommentOutlinedIcon fontSize="medium" />
                  )}
                </IconButton>
                <Typography>{comments?.length}</Typography>
              </Box>
              {!isPostPage && (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <IconButton size="small" onClick={handleCreateSharePostModal}>
                    <ShareOutlinedIcon fontSize="medium" />
                  </IconButton>
                </Box>
              )}
            </CardActions>
            {isShowMessages && (
              <CardContent sx={{ pt: 1 }}>
                <Comments
                  isPostPage={isPostPage}
                  comments={comments}
                  user={user}
                  onDone={init}
                  id={_id}
                />
              </CardContent>
            )}
          </Box>
        )}
      </Card>
    </Box>
  );
};

export default PostCard;
