import { useState, useCallback } from "react";
import { useDropzone } from "react-dropzone";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import CardContent from "@mui/material/CardContent";
import LinearProgress from "@mui/material/LinearProgress";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";

import PreviewFilesList from "../PreviewFilesList";
import { useAuth } from "../../contexts/Auth/Context";
import { useApp } from "../../contexts/App/Context";
import { uploadFiles } from "../../utils/http.js";
import { isVip } from "../../utils/auth";

const ToolBar = ({ onOpen, isProgress }) => (
  <Box sx={{ position: "absolute", top: 12, left: 12, zIndex: 1 }}>
    <Button
      variant="contained"
      color="inherit"
      onClick={onOpen}
      disabled={isProgress}
      startIcon={<AddPhotoAlternateIcon />}
    >
      เพื่มรูปภาพ/วิดีโอ
    </Button>
  </Box>
);

const UploadFiles = ({
  eventId,
  files,
  handleFiles,
  multiple,
  title,
  ...props
}) => {
  const [progress, setProgress] = useState({ percent: 0, filename: "" });
  const { token } = useAuth();
  const { onApp, me } = useApp();
  const isFiles = Boolean(files.length);
  const isProgress = Boolean(progress.percent);

  const THREE_MIN = 1.913e8;
  const ONE_HOUR = 1.8156e10;

  const onDropAccepted = useCallback(
    async (acceptedFiles) => {
      try {
        onApp({ isLoading: true });
        for (let i = 0; i < acceptedFiles.length; i++) {
          const file = acceptedFiles[i];
          await uploadFiles(file, token, eventId, (data) => setProgress(data));
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          });
        }
        handleFiles(acceptedFiles);
      } catch (error) {
        console.error(error);
        alert(error);
      } finally {
        onApp({ isLoading: false });
        setProgress({ percent: 0, filename: "" });
      }
    },
    [token, eventId, onApp, handleFiles]
  );

  const onDropRejected = useCallback((rejectedFiles) => {
    console.warn(rejectedFiles);
    alert(
      "แพ็กเกจของคุณ upload video สูงสุดได้ 3 นาที ลองเปลี่ยนแพ็กเกจเป็น พื้นที่ส่วนตัว ดูสิ"
    );
  }, []);

  const {
    getRootProps,
    getInputProps,
    open,
    isDragReject,
    isDragActive,
    isDragAccept,
  } = useDropzone({
    maxFiles: 10,
    minSize: 0,
    maxSize: isVip(me) ? ONE_HOUR : THREE_MIN,
    accept: "image/jpeg, image/png, video/mp4, video/mpeg, video/quicktime",
    noClick: isFiles,
    onDropAccepted,
    onDropRejected,
    disabled: isProgress,
    multiple,
    ...props,
  });

  return (
    <CardContent>
      {isProgress && (
        <Box>
          <Typography>
            ({progress.percent}%) กำลังอัพโหลด {progress.filename}
          </Typography>
          <LinearProgress variant="determinate" value={progress.percent} />
        </Box>
      )}
      <Box
        {...getRootProps()}
        sx={{
          p: 1,
          border: "1px solid #d9d9d9",
          borderRadius: "4px",
          textAlign: "center",
        }}
      >
        <input {...getInputProps()} />

        {isFiles ? (
          <Box sx={{ position: "relative" }}>
            {multiple && <ToolBar onOpen={open} isProgress={isProgress} />}
            <PreviewFilesList files={files} />
          </Box>
        ) : (
          <Box>
            <AddPhotoAlternateIcon aria-label="add-photo" fontSize="medium" />
            <Typography variant="body2" color="inherit">
              {title}
            </Typography>
            <Typography variant="caption" color="inherit">
              {!isDragActive && "คลิกหรือลากแล้ววาง"}
              {isDragAccept && "ปล่อยได้เลย"}
              {isDragReject &&
                "ไม่รองรับไฟล์นี้ ลองเลือกไฟล์ที่เป็น รูปภาพ/วิดีโอ ดูสิ"}
            </Typography>
          </Box>
        )}
      </Box>
    </CardContent>
  );
};

export default UploadFiles;
