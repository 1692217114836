import { useState, useEffect } from "react";

import AvatarMui from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import CloseIcon from "@mui/icons-material/Close";
import LoadingButton from "@mui/lab/LoadingButton";

import { useApp } from "../../contexts/App/Context";

import Modal from "../Modal";
import UploadFiles from "../UploadFiles";

const Avatar = ({ height, width, src, onChange, children }) => {
  const { isLoading, onGetFiles } = useApp();
  const [isUpload, setIsUpload] = useState(false);
  const [eventId, setEventId] = useState(null);
  const [files, setFiles] = useState([]);

  const handleModal = () => {
    setIsUpload(!isUpload);
    setFiles([]);
  };

  const handleFiles = (data) => setFiles([...files, ...data]);

  const handleGetFiles = async () => {
    try {
      const data = await onGetFiles(eventId);
      onChange(data[0]?.url);
    } catch (error) {
      throw error;
    } finally {
      handleModal();
    }
  };

  useEffect(() => {
    if (isUpload) {
      setEventId(Date.now());
    }
  }, [isUpload]);

  return (
    <Box>
      <Box sx={{ position: "relative" }}>
        <IconButton onClick={handleModal}>
          <AvatarMui
            sx={{ width, height, border: "4px solid white" }}
            src={src}
          >
            {children}
          </AvatarMui>
          <PhotoCameraIcon sx={{ position: "absolute" }} fontSize="medium" />
        </IconButton>
      </Box>
      <Modal isOpen={isUpload} onToggleModal={handleModal}>
        {isUpload && (
          <Card variant="elevation">
            <CardHeader
              subheader={files.length === 1 && "อัพโหลดสำเร็จแล้ว"}
              action={
                <IconButton
                  aria-label="close"
                  onClick={!isLoading ? handleModal : undefined}
                >
                  <CloseIcon />
                </IconButton>
              }
            />
            <CardContent sx={{ p: 0 }}>
              <UploadFiles
                eventId={eventId}
                title="เพื่มรูปภาพ"
                files={files}
                accept="image/jpeg, image/png"
                handleFiles={handleFiles}
              />
            </CardContent>
            <CardActions sx={{ p: 2 }}>
              <LoadingButton
                type="button"
                loading={isLoading}
                disabled={files.length === 0}
                color="success"
                fullWidth
                variant="contained"
                onClick={handleGetFiles}
              >
                ยืนยัน
              </LoadingButton>
            </CardActions>
          </Card>
        )}
      </Modal>
    </Box>
  );
};

export default Avatar;
