import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";

import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import LoadingButton from "@mui/lab/LoadingButton";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import ImageIcon from "@mui/icons-material/Image";
import CloseIcon from "@mui/icons-material/Close";

import { useApp } from "../../contexts/App/Context";
import UploadFiles from "../UploadFiles";

const CreatePostCard = ({
  onDone = () => {},
  isPostPage = false,
  readOnly,
  onToggleModal,
}) => {
  const { me, onCreatePost, onCreatePostPage, isLoading, page } = useApp();
  const [files, setFiles] = useState([]);
  const [isUploadFiles, setIsUploadFiles] = useState(false);
  const [eventId, setEventId] = useState(null);
  const { register, handleSubmit, resetField, watch } = useForm();

  const onSubmit = async (variables) => {
    try {
      if (isPostPage) {
        await onCreatePostPage(variables, eventId);
      } else {
        await onCreatePost(variables, eventId);
      }
      onDone();
    } catch (error) {
      throw error;
    } finally {
      resetField("message");
      setFiles([]);
      onToggleModal();
    }
  };

  const handleFiles = (data) => setFiles([...files, ...data]);

  useEffect(() => {
    if (!readOnly) {
      setEventId(Date.now());
    }
  }, [readOnly]);

  return (
    <Card variant="elevation">
      {isPostPage ? (
        <CardHeader
          avatar={
            <Avatar
              sx={{ width: 40, height: 40 }}
              alt={page?.title}
              src={page?.pagePicture}
              component={Link}
              underline="none"
              color="inherit"
              href={isLoading ? null : `/#/pages/${page?._id}`}
            >
              {page?.title?.charAt(0).toUpperCase()}
            </Avatar>
          }
          title={page?.title}
        />
      ) : (
        <CardHeader
          avatar={
            <Avatar
              sx={{ width: 40, height: 40 }}
              alt={me?.username}
              src={me?.profilePicture}
              component={Link}
              underline="none"
              color="inherit"
              href={isLoading ? null : `/#/profile/${me?._id}`}
            >
              {me?.username?.charAt(0).toUpperCase()}
            </Avatar>
          }
          title={me?.username}
        />
      )}
      <Box component="form" noValidate onSubmit={handleSubmit(onSubmit)}>
        <CardContent>
          <TextField
            fullWidth
            id="message"
            type="text"
            disabled={isLoading}
            placeholder="ลองพิมพ์อะไรสักหน่อยสิ..."
            onClick={readOnly && onToggleModal}
            variant="standard"
            {...register("message")}
          />
        </CardContent>
        {!readOnly && (
          <Box>
            {isUploadFiles && (
              <UploadFiles
                eventId={eventId}
                files={files}
                title="เพื่มรูปภาพ/วิดีโอ ได้ครั้งละ 10 ไฟล์"
                multiple
                handleFiles={handleFiles}
              />
            )}
            <Stack ml={2} mr={2} direction="row" spacing={2}>
              <Button
                variant="outlined"
                color="inherit"
                disabled={isLoading}
                onClick={() => setIsUploadFiles(!isUploadFiles)}
                startIcon={<ImageIcon />}
                endIcon={isUploadFiles && <CloseIcon />}
                size="small"
              >
                รูปภาพ/วิดีโอ
              </Button>
            </Stack>
            <CardActions sx={{ p: 2 }}>
              <LoadingButton
                type="submit"
                loading={isLoading}
                disabled={!Boolean(watch("message")) && files.length === 0}
                fullWidth
                variant="contained"
              >
                โพสต์
              </LoadingButton>
            </CardActions>
          </Box>
        )}
      </Box>
    </Card>
  );
};

export default CreatePostCard;
