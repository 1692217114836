import * as React from "react";

import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";

const Lists = ({ menu = [], label = "", onClick = () => {}, value = "" }) => {
  return (
    <Box component="nav" aria-label={label}>
      <List>
        {menu.map((item) => (
          <ListItemButton
            key={item?.id}
            onClick={() => onClick(item)}
            selected={item.id === value}
          >
            {item?.type === "icon" && <ListItemIcon>{item?.src}</ListItemIcon>}
            {item?.type === "avatar" && (
              <ListItemAvatar>
                <Avatar
                  sx={{ width: 35, height: 35 }}
                  alt={item?.title}
                  src={item?.src}
                >
                  {item?.title?.charAt(0).toUpperCase()}
                </Avatar>
              </ListItemAvatar>
            )}
            <ListItemText primary={item?.title} />
          </ListItemButton>
        ))}
      </List>
    </Box>
  );
};

export default Lists;
