import React, { useState } from "react";

import Avatar from "@mui/material/Avatar";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardActions from "@mui/material/CardActions";
import Button from "@mui/material/Button";
import Link from "@mui/material/Link";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";

import { useApp } from "../../contexts/App/Context";

import { isAdmin, isVip } from "../../utils/auth";

import DialogBox from "../DialogBox";

const MemberCard = (props) => {
  const { _id, username, profilePicture, isWait = false } = props;
  const { onFollowPage, onUnfollowPage, page, me } = useApp();
  const [isDeleteDialogBox, setIsDeleteDialogBox] = useState(false);

  const onApprove = async () => {
    try {
      const payload = { followerId: _id };
      await onFollowPage(page?._id, payload);
    } catch (error) {
      throw error;
    }
  };

  const onReject = async () => {
    try {
      const payload = { followerId: _id };
      await onUnfollowPage(page?._id, payload);
    } catch (error) {
      throw error;
    }
  };

  const handleToggleDeleteDialogBox = () => {
    setIsDeleteDialogBox(!isDeleteDialogBox);
  };

  return (
    <Card variant="outlined">
      <CardHeader
        avatar={
          <Avatar
            sx={{ width: 40, height: 40 }}
            alt={username}
            src={profilePicture}
            component={Link}
            underline="none"
            color="inherit"
            href={`/#/profile/${_id}`}
          >
            {username?.charAt(0).toUpperCase()}
          </Avatar>
        }
        title={username}
        action={
          (isAdmin(me) || isVip(me)) &&
          !isWait && (
            <IconButton
              size="large"
              aria-label="settings"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleToggleDeleteDialogBox}
              color="default"
            >
              <DeleteIcon />
              <DialogBox
                isOpen={isDeleteDialogBox}
                handleClose={handleToggleDeleteDialogBox}
                handleAgree={onReject}
                title={`คุณต้องการลบ ${username} ออกจากเพจใช่ไหม ?`}
                desc={`การลบจะทำให้ ${username} ไม่สามารถเห็นโพสต์ของเพจนี้ได้`}
              />
            </IconButton>
          )
        }
      />
      {isWait && (
        <CardActions>
          <Button
            size="small"
            variant="text"
            color="inherit"
            type="button"
            fullWidth
            onClick={onReject}
          >
            ปฏิเสธ
          </Button>
          <Button
            size="small"
            variant="contained"
            type="button"
            fullWidth
            onClick={onApprove}
          >
            ยอมรับ
          </Button>
        </CardActions>
      )}
    </Card>
  );
};

export default MemberCard;
