import axios from "axios";

const putPreSignedUrl = async (eventId, body, token) => {
  try {
    const { data } = await axios.put(
      `${process.env.REACT_APP_BACKEND_URL}/storage/${eventId}/presigned-url`,
      body,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return data;
  } catch (error) {
    throw error;
  }
};

const getFiles = async (eventId, token) => {
  try {
    const { data } = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}/storage/${eventId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return data;
  } catch (error) {
    throw error;
  }
};

const incrementViews = async (storageId, body, token) => {
  try {
    const { data } = await axios.put(
      `${process.env.REACT_APP_BACKEND_URL}/storage/increment-views`,
      body,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          storageId
        }
      }
    );
    return data;
  } catch (error) {
    throw error;
  }
};

export { putPreSignedUrl, getFiles, incrementViews };
