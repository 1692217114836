import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";

const InfoCard = ({ title = "", lists = [] }) => {
  return (
    <Card variant="elevation">
      <Box sx={{ p: 2 }}>
        <Typography variant="h6" mb={1}>
          {title}
        </Typography>
        <Stack spacing={1}>
          {lists.map(({ text, icon }, index) => (
            <Box key={index} sx={{ display: "flex" }}>
              {icon}
              <Typography variant="body2" ml={1}>
                {text || "-"}
              </Typography>
            </Box>
          ))}
        </Stack>
      </Box>
    </Card>
  );
};

export default InfoCard;
