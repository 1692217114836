import { useState } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Toolbar from "@mui/material/Toolbar";

import PersonIcon from "@mui/icons-material/Person";

import Navbar from "../../components/Navbar";
import Lists from "../../components/Lists";
import ProfileEdit from "../../containers/ProfileEdit";
import Pages from "../../containers/Pages";
import TourIcon from "@mui/icons-material/Tour";

const menu = [
  {
    id: 1,
    title: "โปรไฟล์",
    type: "icon",
    src: <PersonIcon />,
  },
  {
    id: 2,
    title: "เพจ",
    type: "icon",
    src: <TourIcon />,
  },
];

const ProfileSettings = () => {
  const [page, setPage] = useState(1);
  return (
    <Box>
      <Navbar />
      <Toolbar />
      <Container>
        <Typography variant="h5" sx={{ mt: 2, mb: 2 }}>
          การตั้งค่า
        </Typography>
        <Box>
          <Grid container spacing={{ xs: 0, sm: 0, md: 2 }}>
            <Grid item xs={12} sm={12} md={3}>
              <Lists
                label="settings"
                menu={menu}
                onClick={(item) => setPage(item?.id)}
                value={page}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={9}>
              {page === 1 && <ProfileEdit />}
              {page === 2 && <Pages />}
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Box>
  );
};

export default ProfileSettings;
