import axios from "axios";

const getPosts = async (userId, token, params) => {
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}/posts?userId=${userId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params,
      }
    );
    return res?.data;
  } catch (error) {
    throw error;
  }
};

const deletePost = async (postId, token) => {
  try {
    const res = await axios.delete(
      `${process.env.REACT_APP_BACKEND_URL}/posts/${postId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return res?.data;
  } catch (error) {
    throw error;
  }
};

const deletePostPage = async (postPageId, token) => {
  try {
    const res = await axios.delete(
      `${process.env.REACT_APP_BACKEND_URL}/posts-pages/${postPageId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return res?.data;
  } catch (error) {
    throw error;
  }
};

const getPostsPages = async (token, params) => {
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}/posts-pages`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params,
      }
    );
    return res?.data;
  } catch (error) {
    throw error;
  }
};

const getPostsCount = async (userId, token) => {
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}/posts/count?userId=${userId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return res?.data;
  } catch (error) {
    throw error;
  }
};

const getTimeline = async (token, params) => {
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}/posts/timeline`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params,
      }
    );
    return res?.data;
  } catch (error) {
    throw error;
  }
};

const getTimelineCount = async (token) => {
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}/posts/timeline/count`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return res?.data;
  } catch (error) {
    throw error;
  }
};

const likePost = async (postId, token) => {
  try {
    const res = await axios.put(
      `${process.env.REACT_APP_BACKEND_URL}/posts/${postId}/like`,
      null,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return res.data;
  } catch (error) {
    throw error;
  }
};

const likePostPage = async (postPageId, token) => {
  try {
    const res = await axios.put(
      `${process.env.REACT_APP_BACKEND_URL}/posts-pages/${postPageId}/like`,
      null,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return res.data;
  } catch (error) {
    throw error;
  }
};

const createPost = async (body, eventId, token) => {
  try {
    await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}/posts/${eventId}`,
      body,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  } catch (error) {
    throw error;
  }
};

const createPostPage = async (body, eventId, pageId, token) => {
  try {
    await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}/posts-pages/${eventId}`,
      body,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          pageId,
        },
      }
    );
  } catch (error) {
    throw error;
  }
};

const createSharePost = async (body, postId, token) => {
  try {
    await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}/posts/${postId}/share`,
      body,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  } catch (error) {
    throw error;
  }
};

const createSharePostPage = async (body, postPageId, token) => {
  try {
    await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}/posts-pages/${postPageId}/share`,
      body,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  } catch (error) {
    throw error;
  }
};

const createCommentInPost = async (body, postId, token) => {
  try {
    await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}/posts/${postId}/comments`,
      body,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  } catch (error) {
    throw error;
  }
};

const deleteCommentInPost = async (postId, commentId, token) => {
  try {
    await axios.delete(
      `${process.env.REACT_APP_BACKEND_URL}/posts/${postId}/comments/${commentId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  } catch (error) {
    throw error;
  }
};

const createCommentInPostPage = async (body, postPageId, token) => {
  try {
    await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}/posts-pages/${postPageId}/comments`,
      body,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  } catch (error) {
    throw error;
  }
};

const getCommentsInPost = async (postId, token) => {
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}/posts/${postId}/comments`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return res?.data;
  } catch (error) {
    throw error;
  }
};

const getCommentsInPostPage = async (postPageId, token) => {
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}/posts-pages/${postPageId}/comments`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return res?.data;
  } catch (error) {
    throw error;
  }
};

export {
  getPosts,
  getPostsPages,
  getPostsCount,
  likePost,
  likePostPage,
  createPost,
  createPostPage,
  createSharePost,
  createSharePostPage,
  getTimeline,
  getTimelineCount,
  createCommentInPost,
  getCommentsInPost,
  deletePost,
  deletePostPage,
  createCommentInPostPage,
  getCommentsInPostPage,
  deleteCommentInPost,
};
