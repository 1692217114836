import axios from "axios";

const getMessages = async (conversationId, token) => {
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}/messages/${conversationId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return res?.data;
  } catch (error) {
    throw error;
  }
};

const createMessage = async (body, token) => {
  try {
    await axios.post(`${process.env.REACT_APP_BACKEND_URL}/messages`, body, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  } catch (error) {
    throw error;
  }
};

export { getMessages, createMessage };
