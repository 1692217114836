import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Link from "@mui/material/Link";
import Avatar from "@mui/material/Avatar";
import Chip from "@mui/material/Chip";

import { useApp } from "../../contexts/App/Context";
import { isOwner } from "../../utils/auth";

const CommentCard = ({ message, user, _id, onDelete, isOwnerPost }) => {
  const { isLoading, page } = useApp();
  return (
    <Box>
      <Stack direction="row" spacing={2}>
        {isOwner(user, page?.createdBy) ? (
          <Avatar
            sx={{ width: 30, height: 30 }}
            alt={page?.title}
            src={page?.pagePicture}
            component={Link}
            underline="none"
            color="inherit"
            href={isLoading ? null : `/#/pages/${page?._id}`}
          >
            {page?.title?.charAt(0).toUpperCase()}
          </Avatar>
        ) : (
          <Avatar
            sx={{ width: 30, height: 30 }}
            alt={user?.username}
            src={user?.profilePicture}
            component={Link}
            underline="none"
            color="inherit"
            href={isLoading ? null : `/#/profile/${user?._id}`}
          >
            {user?.username?.charAt(0).toUpperCase()}
          </Avatar>
        )}
        <Chip label={message} onDelete={isOwnerPost ? () => onDelete(_id) : null} />
      </Stack>
    </Box>
  );
};

export default CommentCard;
