import React from "react";
import { useForm } from "react-hook-form";

import Avatar from "@mui/material/Avatar";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import LoadingButton from "@mui/lab/LoadingButton";
import Link from "@mui/material/Link";

import FilesList from "../FilesList";
import { useApp } from "../../contexts/App/Context";

const CreateSharePostCard = (props) => {
  const { me, isLoading, onCreateSharePost, onCreateSharePostPage } = useApp();
  const { register: formCreateSharePost, handleSubmit, resetField } = useForm();
  const isMessage = props.message?.length > 0;
  const isFiles = props.storage?.length > 0;

  const onSubmit = async (variables) => {
    try {
      const id = props._id;
      if (props?.isPostPage) {
        await onCreateSharePostPage(variables, id);
      } else {
        await onCreateSharePost(variables, id);
      }
      props?.onDone();
    } catch (error) {
      throw error;
    } finally {
      resetField("message");
      props?.onToggleModal();
    }
  };

  return (
    <Card variant="elevation">
      <CardHeader
        avatar={
          <Avatar
            sx={{ width: 40, height: 40 }}
            alt={me?.username}
            src={me?.profilePicture}
            component={Link}
            underline="none"
            color="inherit"
            href={isLoading ? null : `/#/profile/${me?._id}`}
          >
            {me?.username?.charAt(0)?.toUpperCase()}
          </Avatar>
        }
        title={me?.username}
      />
      <Box component="form" noValidate onSubmit={handleSubmit(onSubmit)}>
        <CardContent>
          <TextField
            fullWidth
            id="message"
            type="text"
            disabled={isLoading}
            placeholder="ลองพิมพ์อะไรสักหน่อยสิ..."
            variant="standard"
            {...formCreateSharePost("message", {
              value: "",
            })}
          />
        </CardContent>
        <CardContent sx={{ maxHeight: 400, overflowY: "scroll" }}>
          <Card variant="outlined">
            <CardContent>
              {isMessage && (
                <Box>
                  <Typography variant="body2">{props?.message}</Typography>
                </Box>
              )}
              {isFiles && (
                <Box sx={{ mt: 2 }}>
                  <FilesList files={props?.storage} />
                </Box>
              )}
            </CardContent>
          </Card>
        </CardContent>
        <Box>
          <CardActions sx={{ p: 2 }}>
            <LoadingButton
              type="submit"
              loading={isLoading}
              fullWidth
              variant="contained"
            >
              แชร์
            </LoadingButton>
          </CardActions>
        </Box>
      </Box>
    </Card>
  );
};

export default CreateSharePostCard;
