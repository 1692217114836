import ModalMaterial from "@mui/material/Modal";
import Box from "@mui/material/Box";

import { useApp } from "../../contexts/App/Context";

const Modal = ({ isOpen, onToggleModal, children }) => {
  const { isLoading } = useApp();
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    maxWidth: 700,
    width: "90%",
    boxShadow: 24,
  };
  return (
    <ModalMaterial
      open={isOpen}
      onClose={isLoading ? null : onToggleModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      keepMounted
    >
      <Box sx={style}>{children}</Box>
    </ModalMaterial>
  );
};

export default Modal;
