import React, { useEffect, useRef } from "react";

import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Chip from "@mui/material/Chip";
import Toolbar from "@mui/material/Toolbar";

import { isOwner } from "../../utils/auth";

import { useApp } from "../../contexts/App/Context";

import Loading from "../../components/Loading";

const Conversation = ({ ws = {} }) => {
  const { conversationId, isLoading, onGetMessages, messages, me } = useApp();
  const scrollRef = useRef();

  useEffect(() => {
    if (conversationId) {
      // http
      onGetMessages(conversationId, null);
    }
  }, [conversationId]);

  useEffect(() => {
    if (ws?.lastJsonMessage) {
      // web socket
      onGetMessages(null, ws.lastJsonMessage);
    }
  }, [ws?.lastJsonMessage]);

  useEffect(() => {
    if (scrollRef) {
      scrollRef.current?.scrollIntoView();
    }
  }, [messages]);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <Box>
      <Toolbar />
      <Stack spacing={1}>
        {messages.map((item) => {
          if (isOwner(me, { _id: item?.sender })) {
            return (
              <Chip
                ref={scrollRef}
                key={item._id}
                label={item.message}
                color="primary"
                sx={{ width: "fit-content", alignSelf: "flex-end" }}
              />
            );
          } else {
            return (
              <Chip
                ref={scrollRef}
                key={item._id}
                label={item.message}
                sx={{ width: "fit-content" }}
              />
            );
          }
        })}
      </Stack>
    </Box>
  );
};

export default Conversation;
