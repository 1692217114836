import { createContext, useContext, useEffect, useState } from "react";

import { useAuth } from "../Auth/Context";
import {
  me,
  user,
  followUser,
  unfollowUser,
  updateMe,
  searchUsers,
  deleteUser,
} from "../../apis/users";
import {
  getPostsPages,
  getPosts,
  createPost,
  createPostPage,
  createSharePost,
  createSharePostPage,
  likePost,
  likePostPage,
  getTimeline,
  getTimelineCount,
  createCommentInPost,
  createCommentInPostPage,
  deleteCommentInPost,
  getCommentsInPost,
  getCommentsInPostPage,
  getPostsCount,
  deletePost,
  deletePostPage,
} from "../../apis/posts";
import { getFiles, incrementViews } from "../../apis/storage";
import { getConversation, createConversation } from "../../apis/conversations";
import { getMessages, createMessage } from "../../apis/messages";
import { createPayment } from "../../apis/payments";
import {
  createPage,
  getPages,
  getPage,
  followPage,
  unfollowPage,
  searchPages,
  updatePage,
  deletePage,
} from "../../apis/pages";

import Loading from "../../components/Loading";

const AppContext = createContext(null);

const AppContextProvider = ({ children }) => {
  const [app, setApp] = useState({
    me: null,
    user: null,
    page: null,
    posts: [],
    postsPages: [],
    timeline: [],
    conversations: [],
    messages: [],
    pages: [],
    isLoading: false,
  });
  const { token } = useAuth();

  const handleApp = (data) => {
    setApp({ ...app, ...data });
  };

  const handleGetMe = async () => {
    try {
      setApp({ ...app, isLoading: true });
      const res = await me(token);
      setApp({ ...app, me: res, isLoading: false });
    } catch (error) {
      console.error(error);
      alert(error.message);
      setApp({ ...app, isLoading: false });
    }
  };

  const handleUpdateMe = async (payload) => {
    try {
      setApp({ ...app, isLoading: true });
      const res = await updateMe(payload, token);
      setApp({ ...app, me: res, isLoading: false });
    } catch (error) {
      console.error(error);
      alert(error.message);
      setApp({ ...app, isLoading: false });
    }
  };

  const handleGetUser = async (userId) => {
    try {
      setApp({ ...app, isLoading: true });
      const res = await user(userId);
      setApp({ ...app, user: res, isLoading: false });
    } catch (error) {
      console.error(error);
      alert(error.message);
      setApp({ ...app, isLoading: false });
    }
  };

  const handlerGetUserAndPosts = async (userId) => {
    try {
      setApp({ ...app, isLoading: true });
      const results = await Promise.all([
        getPosts(userId, token, { limit: 5, skip: 0 }),
        getPostsPages(token, { userId }),
        user(userId),
      ]);
      setApp({
        ...app,
        posts: results[0],
        postsPages: results[1],
        user: results[2],
        isLoading: false,
      });
    } catch (error) {
      console.error(error);
      alert(error.message);
      setApp({ ...app, isLoading: false });
    }
  };

  const handleGetTimeline = async (params) => {
    try {
      setApp({ ...app, isLoading: true });
      const data = await getTimeline(token, params);
      if (data) {
        setApp({
          ...app,
          timeline: app.timeline.concat(data),
          isLoading: false,
        });
      } else {
        setApp({ ...app, isLoading: false });
      }
    } catch (error) {
      console.error(error);
      alert(error.message);
    }
  };

  const handleGetTimelineCount = async () => {
    try {
      const res = await getTimelineCount(token);
      return res?.total;
    } catch (error) {
      console.error(error);
      alert(error.message);
    }
  };

  const handleGetPosts = async (userId, params) => {
    try {
      setApp({ ...app, isLoading: true });
      const data = await getPosts(userId, token, params);
      if (data) {
        setApp({ ...app, posts: app.posts.concat(data), isLoading: false });
      } else {
        setApp({ ...app, isLoading: false });
      }
    } catch (error) {
      console.error(error);
      alert(error.message);
    }
  };

  const handleDeletePost = async (postId) => {
    try {
      setApp({ ...app, isLoading: true });
      await deletePost(postId, token);
      setApp({ ...app, isLoading: false });
    } catch (error) {
      console.error(error);
      alert(error.message);
    }
  };

  const handleDeletePage = async (pageId) => {
    try {
      setApp({ ...app, isLoading: true });
      await deletePage(pageId, token);
    } catch (error) {
      console.error(error);
      alert(error.message);
    } finally {
      setApp({ ...app, isLoading: false });
    }
  };

  const handleDeletePostPage = async (postPageId) => {
    try {
      setApp({ ...app, isLoading: true });
      await deletePostPage(postPageId, token);
      setApp({ ...app, isLoading: false });
    } catch (error) {
      console.error(error);
      alert(error.message);
    }
  };

  const handleDeleteUser = async (userId) => {
    try {
      setApp({ ...app, isLoading: true });
      await deleteUser(userId, token);
      setApp({ ...app, isLoading: false });
    } catch (error) {
      console.error(error);
      alert(error.message);
    }
  };

  const handleGetPostsPages = async (params) => {
    try {
      setApp({ ...app, isLoading: true });
      const data = await getPostsPages(token, params);
      if (data) {
        setApp({
          ...app,
          postsPages: data,
          isLoading: false,
        });
      } else {
        setApp({ ...app, isLoading: false });
      }
    } catch (error) {
      console.error(error);
      alert(error.message);
    }
  };

  const handleGetPageAndPosts = async (pageId, params) => {
    try {
      setApp({ ...app, isLoading: true });
      const results = await Promise.all([
        getPostsPages(token, { pageId }),
        getPage(pageId, token),
      ]);
      setApp({
        ...app,
        postsPages: results[0],
        page: results[1],
        isLoading: false,
      });
    } catch (error) {
      console.error(error);
      alert(error.message);
    }
  };

  const handleGetPages = async () => {
    try {
      setApp({ ...app, isLoading: true });
      const pages = await getPages(token);
      setApp({ ...app, pages, isLoading: false });
    } catch (error) {
      console.error(error);
      alert(error.message);
      setApp({ ...app, isLoading: false });
    }
  };

  const handleGetPage = async (pageId) => {
    try {
      setApp({ ...app, isLoading: true });
      const page = await getPage(pageId, token);
      setApp({ ...app, page, isLoading: false });
    } catch (error) {
      console.error(error);
      alert(error.message);
      setApp({ ...app, isLoading: false });
    }
  };

  const handleUpdatePage = async (pageId, payload) => {
    try {
      setApp({ ...app, isLoading: true });
      await updatePage(pageId, payload, token);
      setApp({ ...app, isLoading: false });
    } catch (error) {
      console.error(error);
      alert(error.message);
      setApp({ ...app, isLoading: false });
    }
  };

  const handleCreatePage = async (body) => {
    try {
      setApp({ ...app, isLoading: true });
      await createPage(body, token);
      setApp({ ...app, isLoading: false });
    } catch (error) {
      console.error(error);
      alert(error.message);
      setApp({ ...app, isLoading: false });
    }
  };

  const handleGetPostsCount = async (userId) => {
    try {
      const res = await getPostsCount(userId, token);
      return res?.total;
    } catch (error) {
      console.error(error);
      alert(error.message);
    }
  };

  const handleCreatePost = async (variables, eventId) => {
    try {
      setApp({ ...app, isLoading: true });
      await createPost(variables, eventId, token);
      setApp({ ...app, isLoading: false });
    } catch (error) {
      console.error(error);
      alert(error.message);
      setApp({ ...app, isLoading: false });
    }
  };

  const handleCreatePostPage = async (variables, eventId) => {
    try {
      setApp({ ...app, isLoading: true });
      const pageId = app.page?._id;
      await createPostPage(variables, eventId, pageId, token);
      setApp({ ...app, isLoading: false });
    } catch (error) {
      console.error(error);
      alert(error.message);
      setApp({ ...app, isLoading: false });
    }
  };

  const handleCreateSharePost = async (variables, postId) => {
    try {
      setApp({ ...app, isLoading: true });
      await createSharePost(variables, postId, token);
      setApp({ ...app, isLoading: false });
    } catch (error) {
      console.error(error);
      alert(error.message);
      setApp({ ...app, isLoading: false });
    }
  };

  const handleCreateSharePostPage = async (variables, postPageId) => {
    try {
      setApp({ ...app, isLoading: true });
      await createSharePostPage(variables, postPageId, token);
      setApp({ ...app, isLoading: false });
    } catch (error) {
      console.error(error);
      alert(error.message);
      setApp({ ...app, isLoading: false });
    }
  };

  const handleDeleteCommentInPost = async (postId, commentId) => {
    try {
      setApp({ ...app, isLoading: true });
      await deleteCommentInPost(postId, commentId, token);
      setApp({ ...app, isLoading: false });
    } catch (error) {
      console.error(error);
      alert(error.message);
      setApp({ ...app, isLoading: false });
    }
  };


  const handleCreateCommentInPost = async (variables, postId) => {
    try {
      setApp({ ...app, isLoading: true });
      await createCommentInPost(variables, postId, token);
      setApp({ ...app, isLoading: false });
    } catch (error) {
      console.error(error);
      alert(error.message);
      setApp({ ...app, isLoading: false });
    }
  };

  const handleCreateCommentInPostPage = async (variables, postPageId) => {
    try {
      setApp({ ...app, isLoading: true });
      await createCommentInPostPage(variables, postPageId, token);
      setApp({ ...app, isLoading: false });
    } catch (error) {
      console.error(error);
      alert(error.message);
      setApp({ ...app, isLoading: false });
    }
  };

  const handleGetCommentsInPost = async (postId) => {
    try {
      setApp({ ...app, isLoading: true });
      const post = await getCommentsInPost(postId, token);
      setApp({ ...app, isLoading: false });
      return post?.comments;
    } catch (error) {
      console.error(error);
      alert(error.message);
      setApp({ ...app, isLoading: false });
    }
  };

  const handleGetCommentsInPostPage = async (postPageId) => {
    try {
      setApp({ ...app, isLoading: true });
      const postPage = await getCommentsInPostPage(postPageId, token);
      setApp({ ...app, isLoading: false });
      return postPage?.comments;
    } catch (error) {
      console.error(error);
      alert(error.message);
      setApp({ ...app, isLoading: false });
    }
  };

  const handleLikePost = async (postId) => {
    try {
      setApp({ ...app, isLoading: true });
      await likePost(postId, token);
      setApp({ ...app, isLoading: false });
    } catch (error) {
      console.error(error);
      alert(error.message);
      setApp({ ...app, isLoading: false });
    }
  };

  const handleLikePostPage = async (postPageId) => {
    try {
      setApp({ ...app, isLoading: true });
      await likePostPage(postPageId, token);
      setApp({ ...app, isLoading: false });
    } catch (error) {
      console.error(error);
      alert(error.message);
      setApp({ ...app, isLoading: false });
    }
  };

  const handleFollowUser = async (followingId) => {
    try {
      setApp({ ...app, isLoading: true });
      const me = await followUser(followingId, token);
      setApp({ ...app, me, isLoading: false });
    } catch (error) {
      console.error(error);
      alert(error.message);
      setApp({ ...app, isLoading: false });
    }
  };

  const handleFollowPage = async (pageId, payload = null) => {
    try {
      setApp({ ...app, isLoading: true });
      const page = await followPage(pageId, payload, token);
      setApp({ ...app, page, isLoading: false });
    } catch (error) {
      console.error(error);
      alert(error.message);
      setApp({ ...app, isLoading: false });
    }
  };

  const handleUnfollowUser = async (followingId) => {
    try {
      setApp({ ...app, isLoading: true });
      const me = await unfollowUser(followingId, token);
      setApp({ ...app, me, isLoading: false });
    } catch (error) {
      console.error(error);
      alert(error.message);
      setApp({ ...app, isLoading: false });
    }
  };

  const handleUnfollowPage = async (pageId, payload = null) => {
    try {
      setApp({ ...app, isLoading: true });
      const page = await unfollowPage(pageId, payload, token);
      setApp({ ...app, page, isLoading: false });
    } catch (error) {
      console.error(error);
      alert(error.message);
      setApp({ ...app, isLoading: false });
    }
  };

  const handleGetFiles = async (eventId) => {
    try {
      setApp({ ...app, isLoading: true });
      const data = await getFiles(eventId, token);
      setApp({ ...app, isLoading: false });
      return data;
    } catch (error) {
      console.error(error);
      alert(error);
      setApp({ ...app, isLoading: false });
    }
  };

  const handleIncrementViews = async (storageId, payload) => {
    try {
      const data = await incrementViews(storageId, payload, token);
      return data;
    } catch (error) {
      console.error(error);
      alert(error);
    }
  };

  const handleSearchUsers = async (query) => {
    try {
      const data = await searchUsers(query, token);
      return data;
    } catch (error) {
      console.error(error);
      alert(error);
    }
  };

  const handleSearchPages = async (query) => {
    try {
      const data = await searchPages(query, token);
      return data;
    } catch (error) {
      console.error(error);
      alert(error);
    }
  };

  const handleGetConversation = async () => {
    try {
      setApp({ ...app, isLoading: true });
      const data = await getConversation(token);
      setApp({
        ...app,
        conversations: data,
        isLoading: false,
      });
    } catch (error) {
      console.error(error);
      alert(error.message);
      setApp({ ...app, isLoading: false });
    }
  };

  const handleCreateConversation = async (body) => {
    try {
      setApp({ ...app, isLoading: true });
      await createConversation(body, token);
      setApp({
        ...app,
        isLoading: false,
      });
    } catch (error) {
      console.error(error);
      alert(error.message);
      setApp({ ...app, isLoading: false });
    }
  };

  const handleGetMessages = async (conversationId, newMessage = null) => {
    try {
      if (conversationId) {
        setApp({ ...app, isLoading: true });
        const data = await getMessages(conversationId, token);
        setApp({
          ...app,
          messages: data,
          isLoading: false,
        });
      }

      if (newMessage) {
        const message = {
          _id: Date.now(),
          sender: newMessage.sender,
          message: newMessage.message,
        };
        setApp({ ...app, messages: [...app.messages, message] });
      }
    } catch (error) {
      console.error(error);
      alert(error.message);
      setApp({ ...app, isLoading: false });
    }
  };

  const handleCreateMessage = async (body) => {
    try {
      await createMessage(body, token);
    } catch (error) {
      console.error(error);
      alert(error.message);
    }
  };

  const handleCreatePayment = async (body) => {
    try {
      return await createPayment(body, token);
    } catch (error) {
      console.error(error);
      alert(error.message);
    }
  };

  useEffect(() => {
    handleGetMe();
  }, []);

  const value = {
    ...app,
    onApp: handleApp,
    onGetPosts: handleGetPosts,
    onGetPostsPages: handleGetPostsPages,
    onGetPageAndPosts: handleGetPageAndPosts,
    onGetPostsCount: handleGetPostsCount,
    onGetTimeline: handleGetTimeline,
    onGetTimelineCount: handleGetTimelineCount,
    onCreatePost: handleCreatePost,
    onCreatePostPage: handleCreatePostPage,
    onCreateSharePost: handleCreateSharePost,
    onCreateSharePostPage: handleCreateSharePostPage,
    onCreateCommentInPost: handleCreateCommentInPost,
    onDeleteCommentInPost: handleDeleteCommentInPost,
    onCreateCommentInPostPage: handleCreateCommentInPostPage,
    onGetCommentsInPost: handleGetCommentsInPost,
    onGetCommentsInPostPage: handleGetCommentsInPostPage,
    onLikePost: handleLikePost,
    onLikePostPage: handleLikePostPage,
    onGetMe: handleGetMe,
    onUpdateMe: handleUpdateMe,
    onGetUser: handleGetUser,
    onGetUserAndPosts: handlerGetUserAndPosts,
    onFollowUser: handleFollowUser,
    onUnfollowUser: handleUnfollowUser,
    onFollowPage: handleFollowPage,
    onUnfollowPage: handleUnfollowPage,
    onGetFiles: handleGetFiles,
    onIncrementViews: handleIncrementViews,
    onSearchUsers: handleSearchUsers,
    onGetConversation: handleGetConversation,
    onCreateConversation: handleCreateConversation,
    onGetMessages: handleGetMessages,
    onCreateMessage: handleCreateMessage,
    onGetPages: handleGetPages,
    onGetPage: handleGetPage,
    onCreatePage: handleCreatePage,
    onCreatePayment: handleCreatePayment,
    onDeletePost: handleDeletePost,
    onDeletePostPage: handleDeletePostPage,
    onDeleteUser: handleDeleteUser,
    onSearchPages: handleSearchPages,
    onUpdatePage: handleUpdatePage,
    onDeletePage: handleDeletePage,
  };

  if (!app?.me) return <Loading />;

  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
};

const useApp = () => useContext(AppContext);

export default AppContextProvider;
export { useApp };
