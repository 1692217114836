import React, { useEffect, useState } from "react";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import { isOwner } from "../../utils/auth";

import Lists from "../../components/Lists";

import { useApp } from "../../contexts/App/Context";

const Conversations = () => {
  const { conversations, onGetConversation, onApp, conversationId, me } =
    useApp();
  const [menu, setMenu] = useState([]);

  useEffect(() => {
    onGetConversation();
  }, []);

  useEffect(() => {
    const data = conversations?.map(({ members, _id }) => {
      const receiver = members?.find(({ _id }) => !isOwner(me, { _id }));
      return {
        id: _id,
        title: receiver?.username,
        type: "avatar",
        src: receiver?.profilePicture,
      };
    });
    setMenu(data);
  }, [conversations, me]);

  return (
    <Box>
      <Typography variant="h5" gutterBottom component="div">
        แชท
      </Typography>
      <Lists
        label="conversations"
        menu={menu}
        value={conversationId && conversationId}
        onClick={(item) => onApp({ conversationId: item?.id })}
      />
    </Box>
  );
};

export default Conversations;
