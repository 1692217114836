import { useEffect } from "react";
import { useForm } from "react-hook-form";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import TextField from "@mui/material/TextField";
import LoadingButton from "@mui/lab/LoadingButton";
import Grid from "@mui/material/Grid";

import { useApp } from "../../contexts/App/Context";

import { isVip } from "../../utils/auth";

import Avatar from "../../components/Avatar";
import Background from "../../components/Background";

const ProfileEdit = () => {
  const { me, isLoading, onUpdateMe } = useApp();
  const isMeVip = isVip(me);
  const {
    register: formProfile,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
  } = useForm();

  useEffect(() => {
    setValue("username", me?.username);
    setValue("email", me?.email);
    setValue("profilePicture", me?.profilePicture);
    setValue("coverPicture", me?.coverPicture);
  }, [me, setValue]);

  const onSubmit = async (variables) => {
    try {
      await onUpdateMe(variables);
    } catch (error) {
      throw error;
    }
  };

  return (
    <Card variant="outlined">
      <Box component="form" noValidate onSubmit={handleSubmit(onSubmit)}>
        <CardContent>
          <Grid item xs={12} sm={12} md={12}>
            <Box sx={{ position: "relative" }}>
              <Background
                editable
                alt={me?.username}
                src={watch("coverPicture", me?.coverPicture)}
                maxHeight={{ xs: 200, md: 270 }}
                onChange={(url) => setValue("coverPicture", url)}
              />
              <Box
                sx={{
                  position: "absolute",
                  top: { xs: 130, md: 200 },
                  left: "50%",
                  transform: "translateX(-50%)",
                  textAlign: "center",
                }}
              >
                <Avatar
                  width={{ xs: 110, md: 130 }}
                  height={{ xs: 110, md: 130 }}
                  src={watch("profilePicture", me?.profilePicture)}
                  onChange={(url) => setValue("profilePicture", url)}
                >
                  <Typography variant="h3">
                    {me?.username.charAt(0).toUpperCase()}
                  </Typography>
                </Avatar>
              </Box>
            </Box>
            <Box sx={{ height: 75 }} />
            <Box>
              <TextField
                label="แพ็กเกจ"
                variant="standard"
                value={isMeVip ? "พื้นที่ส่วนตัว" : "ฟรี"}
                disabled
                fullWidth
                sx={{ mb: 2 }}
              />
            </Box>
            <Box>
              <TextField
                id="username"
                label="ชื่อเล่น"
                variant="standard"
                autoComplete="username"
                required
                disabled={isLoading}
                fullWidth
                error={Boolean(errors.username)}
                helperText={errors.username?.message}
                {...formProfile("username", {
                  required: "ป้อนชื่อเล่น",
                  minLength: {
                    value: 3,
                    message: "ชื่อเล่นต้องมากกว่า 6 ตัวขึ้นไป",
                  },
                  maxLength: {
                    value: 30,
                    message: "ชื่อเล่นต้องน้อยกว่า 30 ตัว",
                  },
                })}
              />
            </Box>
            <Box>
              <TextField
                required
                variant="standard"
                margin="normal"
                fullWidth
                id="email"
                label="อีเมล"
                disabled={isLoading}
                autoComplete="email"
                error={Boolean(errors.email)}
                helperText={errors.email?.message}
                {...formProfile("email", {
                  required: "ป้อนอีเมล",
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: "รูปแบบอีเมลไม่ถูกต้อง",
                  },
                })}
              />
            </Box>
          </Grid>
        </CardContent>
        <CardActions>
          <LoadingButton
            type="submit"
            fullWidth
            loading={isLoading}
            variant="contained"
            sx={{ mb: 1 }}
          >
            บันทึก
          </LoadingButton>
        </CardActions>
      </Box>
    </Card>
  );
};

export default ProfileEdit;
