import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Avatar from "@mui/material/Avatar";
import LoadingButton from "@mui/lab/LoadingButton";
import Stack from "@mui/material/Stack";
import SettingsIcon from "@mui/icons-material/Settings";
import Link from "@mui/material/Link";
import Toolbar from "@mui/material/Toolbar";
import MessageIcon from "@mui/icons-material/Message";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import PersonRemoveIcon from "@mui/icons-material/PersonRemove";
import DeleteIcon from "@mui/icons-material/Delete";
import EmailIcon from "@mui/icons-material/Email";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import Tooltip from "@mui/material/Tooltip";

import InfoCard from "../../containers/InfoCard";
import Navbar from "../../components/Navbar";
import Background from "../../components/Background";
import DialogBox from "../../components/DialogBox";

import Posts from "../../containers/Posts";

import { useApp } from "../../contexts/App/Context";

import { isAdmin, isVip } from "../../utils/auth";

const Profile = () => {
  const [isDeleteDialogBox, setIsDeleteDialogBox] = useState(false);
  const params = useParams();
  const navigate = useNavigate();
  const { userId } = params;
  const {
    user,
    posts,
    onGetUserAndPosts,
    me,
    isLoading,
    onFollowUser,
    onUnfollowUser,
    onCreateConversation,
    onDeleteUser,
  } = useApp();

  const isFollowing = me.followings?.includes(user?._id);
  const isMe = me?._id === user?._id;
  const isAccessVip = isVip(me) === false && isVip(user) === true;

  const lists = [
    {
      icon: <EmailIcon fontSize="small" />,
      text: user?.email,
    },
    {
      icon: <PeopleAltIcon fontSize="small" />,
      text: `ผู้ติดตาม ${user?.followers.length} คน`,
    },
    {
      icon: <TrendingUpIcon fontSize="small" />,
      text: `กำลังติดตาม ${user?.followings.length} คน`,
    },
  ];

  const handleToggleDeleteDialogBox = () => {
    setIsDeleteDialogBox(!isDeleteDialogBox);
  };

  const onDone = async () => {
    try {
      await onGetUserAndPosts(userId);
    } catch (error) {
      throw error;
    }
  };

  const handleFollow = async () => {
    try {
      if (isFollowing) {
        await onUnfollowUser(user._id);
      } else {
        await onFollowUser(user._id);
      }
    } catch (error) {
      throw error;
    }
  };

  const handleConversation = async () => {
    try {
      const body = { receiverId: user._id };
      await onCreateConversation(body);
      navigate(`/profile/${me?._id}/messages`);
    } catch (error) {
      throw error;
    }
  };

  const handleDeleteUser = async () => {
    try {
      await onDeleteUser(user?._id);
      navigate(`/`);
    } catch (error) {
      throw error;
    }
  };

  useEffect(() => {
    const init = async () => {
      try {
        await onGetUserAndPosts(userId);
      } catch (error) {
        throw error;
      }
    };
    init();
  }, [userId]);

  return (
    <Box>
      <Navbar />
      <Toolbar />
      <Background alt={user?.username} src={user?.coverPicture} />
      <Box
        sx={{
          position: "absolute",
          top: { xs: 200, md: 340 },
          left: "50%",
          transform: "translateX(-50%)",
          textAlign: "center",
        }}
      >
        <Avatar
          alt={user?.username}
          src={user?.profilePicture}
          sx={{
            width: { xs: 120, md: 150 },
            height: { xs: 120, md: 150 },
            border: "4px solid white",
            margin: "0 auto",
          }}
        >
          <Typography variant="h3">
            {user?.username?.charAt(0).toUpperCase()}
          </Typography>
        </Avatar>
        <Typography noWrap variant="h5" sx={{ mt: 1 }}>
          {user?.username}
        </Typography>
      </Box>
      <Box
        sx={{
          position: "relative",
          top: { xs: 120, md: 140 },
        }}
      >
        <Container maxWidth="md">
          <Stack
            direction="row"
            spacing={2}
            justifyContent="flex-end"
            alignItems="center"
            sx={{ mb: 3 }}
          >
            {isMe ? (
              <Box>
                <LoadingButton
                  component={Link}
                  underline="none"
                  href={`/#/profile/settings`}
                  startIcon={<SettingsIcon />}
                  loading={isLoading}
                  variant="contained"
                >
                  การตั้งค่า
                </LoadingButton>
              </Box>
            ) : (
              <Box>
                {isAdmin(me) && (
                  <LoadingButton
                    type="button"
                    loading={isLoading}
                    sx={{ mr: 1 }}
                    startIcon={<DeleteIcon />}
                    variant="outlined"
                    onClick={handleToggleDeleteDialogBox}
                    color="inherit"
                  >
                    <Box>ลบ</Box>
                    <DialogBox
                      isOpen={isDeleteDialogBox}
                      handleClose={handleToggleDeleteDialogBox}
                      handleAgree={handleDeleteUser}
                      title={`คุณต้องการลบ ${user?.username} ใช่ไหม ?`}
                      desc="ผู้ใช้คนนี้จะถูกลบโดยถาวร และไม่สามารถกู้คืนได้ในภายหลัง"
                    />
                  </LoadingButton>
                )}

                <Tooltip
                  title={
                    isAccessVip
                      ? "สมัครแพ็กเกจ พื้นที่ส่วนตัว เพื่อปลดล็อก"
                      : ""
                  }
                >
                  <Box component="span">
                    <LoadingButton
                      type="button"
                      loading={isLoading}
                      variant={isFollowing ? "outlined" : "contained"}
                      startIcon={
                        isFollowing ? <PersonRemoveIcon /> : <PersonAddIcon />
                      }
                      onClick={handleFollow}
                      disabled={isAccessVip}
                      color="inherit"
                    >
                      {isFollowing ? "เลิกติดตาม" : "ติดตาม"}
                    </LoadingButton>
                  </Box>
                </Tooltip>

                <LoadingButton
                  type="button"
                  loading={isLoading}
                  sx={{ ml: 1 }}
                  startIcon={<MessageIcon />}
                  variant="contained"
                  onClick={handleConversation}
                  color="primary"
                >
                  ส่งข้อความ
                </LoadingButton>
              </Box>
            )}
          </Stack>
          <Grid container spacing={{ xs: 2, sm: 2, md: 2 }}>
            <Grid item xs={12} sm={12} md={6}>
              {user && <InfoCard title="แนะนำตัว" lists={lists} />}
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              {posts && <Posts onDone={onDone} />}
            </Grid>
          </Grid>
        </Container>
      </Box>
    </Box>
  );
};

export default Profile;
