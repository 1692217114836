import { createContext, useContext, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import { login, logout, register, facebook } from "../../apis/auth";

const AuthContext = createContext(null);

const AuthContextProvider = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const [auth, setAuth] = useState({
    token: window.localStorage.getItem("token") || null,
    isLoading: false,
  });

  const handleLogin = async (variables) => {
    try {
      setAuth({ ...auth, isLoading: true });
      await login(variables);
      setAuth({
        ...auth,
        token: window.localStorage.getItem("token"),
        isLoading: false,
      });
      const origin = location.state?.from?.pathname || "/";
      navigate(origin);
    } catch (error) {
      console.error(error);
      alert(error.message);
      setAuth({ ...auth, isLoading: false });
    }
  };

  const handleFacebook = async (variables) => {
    try {
      setAuth({ ...auth, isLoading: true });
      await facebook(variables);
      setAuth({
        ...auth,
        token: window.localStorage.getItem("token"),
        isLoading: false,
      });
      const origin = location.state?.from?.pathname || "/";
      navigate(origin);
    } catch (error) {
      console.error(error);
      alert(error.message);
      setAuth({ ...auth, isLoading: false });
    }
  };

  const handleLogout = async () => {
    try {
      setAuth({
        ...auth,
        isLoading: true,
      });
      await logout(auth.token);
      setAuth({ ...auth, token: null, isLoading: false });
      navigate("/login");
    } catch (error) {
      console.error(error);
      alert(error.message);
      setAuth({
        ...auth,
        isLoading: false,
      });
    }
  };

  const handleRegister = async (variables) => {
    try {
      setAuth({ ...auth, isLoading: true });
      await register(variables);
      setAuth({ ...auth, isLoading: false });
      navigate("/login");
    } catch (error) {
      console.error(error);
      alert(error.message);
      setAuth({ ...auth, isLoading: false });
    }
  };

  const value = {
    ...auth,
    onLogin: handleLogin,
    onFacebook: handleFacebook,
    onLogout: handleLogout,
    onRegister: handleRegister,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

const useAuth = () => useContext(AuthContext);

export default AuthContextProvider;
export { useAuth };
