import React, { useEffect, useState } from "react";

import SearchIcon from "@mui/icons-material/Search";

import debounce from "lodash.debounce";
import Avatar from "@mui/material/Avatar";
import Stack from "@mui/material/Stack";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import Autocomplete from "@mui/material/Autocomplete";

import { useApp } from "../../contexts/App/Context";
import { SearchWrapper, SearchIconWrapper, StyledInputBase } from "./style";

const Search = () => {
  const { onSearchUsers, onSearchPages } = useApp();
  const [inputText, setInputText] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(isOpen && options?.length === 0);

  useEffect(() => {
    if (inputText?.length > 0) {
      setIsOpen(true);
    } else {
      setIsOpen(false);
    }
  }, [inputText]);

  const getData = async (text) => {
    try {
      setIsLoading(true);
      const data = await Promise.all([
        onSearchUsers(`username=${text}`),
        onSearchPages(`title=${text}`),
      ]).then((data) => {
        const users = data[0]?.map((item) => ({ ...item, type: "USER" }));
        const pages = data[1]?.map((item) => ({ ...item, type: "PAGE" }));
        return [...users, ...pages];
      });

      setOptions(data);
    } catch (error) {
      throw error;
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Stack spacing={2}>
      <SearchWrapper>
        <SearchIconWrapper>
          <SearchIcon color="action" />
        </SearchIconWrapper>
        <Autocomplete
          id="search"
          freeSolo
          disableClearable
          options={options}
          getOptionLabel={(option) => {
            const { type } = option;
            if (type === "USER") return option.username;
            if (type === "PAGE") return option.title;
            return "";
          }}
          disablePortal
          open={isOpen}
          onClose={() => setIsOpen(false)}
          loading={isLoading}
          loadingText="กำลังโหลด..."
          onInputChange={debounce(async (e, text) => {
            setInputText(text);
            const regex = /^[^\s]+(\s+[^\s]+)*$/;
            if (text && regex.test(text)) {
              getData(text);
            }
          }, 600)}
          renderInput={(params) => (
            <StyledInputBase
              placeholder="ค้นหา"
              inputProps={{
                ...params.inputProps,
                "aria-label": "search",
              }}
              {...params.InputProps}
            />
          )}
          renderOption={(props, option) => {
            const isUser = option.type === "USER";
            const isPage = option.type === "PAGE";
            if (isUser) {
              return (
                <Stack
                  component={Link}
                  key={option._id}
                  sx={{ p: "5px 10px", alignItems: "center" }}
                  direction="row"
                  underline="none"
                  color="inherit"
                  onClick={() => setIsOpen(false)}
                  href={`/#/profile/${option?._id}`}
                >
                  <Avatar
                    sx={{ width: 32, height: 32 }}
                    alt={option?.username}
                    src={option?.profilePicture}
                  >
                    {option?.username?.charAt(0).toUpperCase()}
                  </Avatar>
                  <Typography noWrap sx={{ ml: 1 }}>
                    {option?.username}
                  </Typography>
                </Stack>
              );
            }

            if (isPage) {
              return (
                <Stack
                  component={Link}
                  key={option._id}
                  sx={{ p: "5px 10px", alignItems: "center" }}
                  direction="row"
                  underline="none"
                  color="inherit"
                  onClick={() => setIsOpen(false)}
                  href={`/#/pages/${option?._id}`}
                >
                  <Avatar
                    sx={{ width: 32, height: 32 }}
                    alt={option?.title}
                    src={option?.pagePicture}
                  >
                    {option?.title?.charAt(0).toUpperCase()}
                  </Avatar>
                  <Typography noWrap sx={{ ml: 1 }}>
                    {option?.title}
                  </Typography>
                </Stack>
              );
            }
          }}
        />
      </SearchWrapper>
    </Stack>
  );
};

export default Search;
