import axios from "axios";

const createPage = async (body, token) => {
  try {
    await axios.post(`${process.env.REACT_APP_BACKEND_URL}/pages`, body, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  } catch (error) {
    throw error;
  }
};

const getPages = async (token) => {
  try {
    const res = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/pages`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return res?.data;
  } catch (error) {
    throw error;
  }
};

const getPage = async (pageId, token) => {
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}/pages/${pageId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return res?.data;
  } catch (error) {
    throw error;
  }
};

const updatePage = async (pageId, payload, token) => {
  try {
    await axios.put(
      `${process.env.REACT_APP_BACKEND_URL}/pages/${pageId}`,
      payload,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  } catch (error) {
    throw error;
  }
};

const followPage = async (pageId, payload, token) => {
  try {
    const res = await axios.put(
      `${process.env.REACT_APP_BACKEND_URL}/pages/${pageId}/follow`,
      payload,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (!res.data?._id) throw res.data;
    return res.data;
  } catch (error) {
    throw error;
  }
};

const unfollowPage = async (pageId, payload, token) => {
  try {
    const res = await axios.put(
      `${process.env.REACT_APP_BACKEND_URL}/pages/${pageId}/unfollow`,
      payload,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (!res.data?._id) throw res.data;
    return res.data;
  } catch (error) {
    throw error;
  }
};

const searchPages = async (query, token) => {
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}/pages/search?${query}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return res.data;
  } catch (error) {
    throw error;
  }
};

const deletePage = async (pageId, token) => {
  try {
    await axios.delete(`${process.env.REACT_APP_BACKEND_URL}/pages/${pageId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  } catch (error) {
    throw error;
  }
};

export {
  createPage,
  getPages,
  getPage,
  followPage,
  unfollowPage,
  searchPages,
  updatePage,
  deletePage,
};
