import ReactPlayer from 'react-player'
import { ClientJS } from 'clientjs';

import Box from "@mui/material/Box";
import ImageListMui from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import CardMedia from "@mui/material/CardMedia";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";

import { useApp } from "../../contexts/App/Context"

const TAG = {
  video: "video",
  image: "img",
};


const FilesList = ({ files = [] }) => {
  const { onIncrementViews } = useApp()

  const gridNumber = (files) => {
    let number = 3;
    if (files.length === 1) number = 1;
    if (files.length === 2) number = 2;
    return number;
  };

  const getElementByMime = (mimeType) => {
    const type = mimeType?.split("/")[0];
    return TAG[type];
  };

  const onIncrementViewVideo = async (file) => {
    const fingerprint = new ClientJS().getFingerprint()
    try {
      const payload = JSON.stringify({ deviceId: fingerprint })
      await onIncrementViews(file?._id, payload)
    } catch (error) {
      throw error
    }
  }

  return (
    <ImageListMui cols={gridNumber(files)} gap={5}>
      {files.map((file, i) => {
        const element = getElementByMime(file?.metadata?.contentType);
        return (
          <ImageListItem key={i}>
            {element === TAG.video ? (
              <ReactPlayer
                url={file?.url}
                controls
                width="100%"
                height="100%"
                onStart={() => onIncrementViewVideo(file)}
                wrapper={(props) => <Box sx={props.style} position="relative">
                  <Box position="absolute" right="0px">
                    <IconButton size="small" sx={{ color: "white" }} >
                      <Typography mr={1}>{file?.devicesId?.length || 0} คนดู</Typography>
                    </IconButton>
                  </Box>
                  {props.children}
                </Box>} />
            ) : (
              <CardMedia
                component={element}
                image={file.url}
                title={file.filename}
              />
            )}
          </ImageListItem>
        );
      })}
    </ImageListMui>
  );
};

export default FilesList;
