import axios from "axios";

import { putPreSignedUrl } from "../apis/storage.jsx";

const request = async (url, options) => {
  try {
    await axios(url, options);
  } catch (error) {
    throw error;
  }
};

const uploadFiles = async (file, token, eventId, onProgress) => {
  const body = {
    description: "", // TODO: should read from text input field
    metadata: {
      originalName: encodeURIComponent(file?.name),
      lastModified: file?.lastModified,
      size: file?.size,
      contentType: file?.type,
    },
  };
  try {
    const data = await putPreSignedUrl(eventId, body, token);
    await request(data?.url, {
      method: "PUT",
      data: file,
      headers: {
        "Content-Type": file?.type,
      },
      onUploadProgress: (progress) => {
        const percent = Math.round((progress.loaded * 100) / progress.total);
        onProgress({ percent, filename: file.name });
      },
    });
  } catch (error) {
    throw error;
  }
};

export { uploadFiles };
