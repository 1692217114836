import { useEffect, useState } from "react";

import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Skeleton from "@mui/material/Skeleton";

import CreatePostCard from "../../components/CreatePostCard";
import PostCard from "../../components/PostCard";
import SharePostCard from "../../components/SharePostCard";
import Modal from "../../components/Modal";
import { useApp } from "../../contexts/App/Context";
import { isOwner } from "../../utils/auth";

const Posts = ({ onDone }) => {
  const { me, user, posts, postsPages, onGetPostsCount, onGetPosts } = useApp();
  const [isCreatePost, setCreatePost] = useState(false);
  const [total, setTotal] = useState(0);
  const [observedEl, setObservedEl] = useState(null);

  const isLoadMore = total > posts.length;

  useEffect(() => {
    const loadMore = async () => {
      try {
        if (user) {
          await onGetPosts(user?._id, {
            limit: 5,
            skip: isLoadMore ? posts.length : total,
          });
        }
      } catch (error) {
        throw error;
      }
    };

    const observer = new IntersectionObserver(
      async (items) => {
        if (items[0].isIntersecting) {
          await loadMore();
        }
      },
      {
        threshold: 1,
      }
    );
    if (observedEl) {
      observer.observe(observedEl);
    }

    return () => {
      if (observedEl) {
        observer.unobserve(observedEl);
      }
    };
  }, [observedEl, posts, isLoadMore, total]);

  useEffect(() => {
    const init = async () => {
      try {
        if (user) {
          const total = await onGetPostsCount(user?._id);
          setTotal(total);
        }
      } catch (error) {
        throw error;
      }
    };
    init();
  }, [user]);

  const handleCreatePostModal = () => {
    setCreatePost(!isCreatePost);
  };

  return (
    <Box sx={{ mb: 2 }}>
      {isOwner(me, user) && (
        <Box>
          <CreatePostCard readOnly onToggleModal={handleCreatePostModal} />
          <Modal isOpen={isCreatePost} onToggleModal={handleCreatePostModal}>
            {isCreatePost && (
              <CreatePostCard
                onDone={onDone}
                onToggleModal={handleCreatePostModal}
              />
            )}
          </Modal>
          <Box sx={{ mb: 2 }} />
        </Box>
      )}
      <Stack spacing={2}>
        {posts.map((post) => {
          if (post?.shareId) {
            return <SharePostCard key={post._id} onDone={onDone} {...post} />;
          }
          return <PostCard key={post._id} onDone={onDone} {...post} />;
        })}
        {isLoadMore && <Skeleton ref={setObservedEl} />}

        {postsPages.map((postPage) => {
          if (postPage?.shareId) {
            return (
              <SharePostCard
                isPostPage
                key={postPage._id}
                onDone={onDone}
                {...postPage}
              />
            );
          }
          return (
            <PostCard
              isPostPage
              key={postPage._id}
              onDone={onDone}
              {...postPage}
            />
          );
        })}
      </Stack>
    </Box>
  );
};

export default Posts;
