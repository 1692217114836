import React, { useState, useEffect, useCallback } from "react";

import Avatar from "@mui/material/Avatar";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import FavoriteOutlinedIcon from "@mui/icons-material/FavoriteOutlined";
import ModeCommentOutlinedIcon from "@mui/icons-material/ModeCommentOutlined";
import ModeCommentIcon from "@mui/icons-material/ModeComment";
import ShareOutlinedIcon from "@mui/icons-material/ShareOutlined";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import DeleteIcon from "@mui/icons-material/Delete";
import ListItemIcon from "@mui/material/ListItemIcon";

import Comments from "../../containers/Comments";
import { useApp } from "../../contexts/App/Context";
import { format } from "../../utils/datetime";
import { isAdmin, isOwner } from "../../utils/auth";
import PostCard from "../PostCard";
import Modal from "../Modal";
import DialogBox from "../DialogBox";
import CreateSharePostCard from "../CreateSharePostCard";

const SharePostCard = (props) => {
  const {
    likes = [],
    message = "",
    _id = "",
    shareId,
    createdAt,
    isPostPage = false,
  } = props;
  const {
    me,
    onLikePost,
    onLikePostPage,
    isLoading,
    onDeletePost,
    onDeletePostPage,
    onGetCommentsInPost,
    onGetCommentsInPostPage,
  } = useApp();
  const [isShowMessages, setIsShowMessages] = useState(false);
  const [totalLike, setTotalLike] = useState(likes.length);
  const [isLiked, setIsLiked] = useState(false);
  const [isSharePost, setSharePost] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [comments, setComments] = useState(props?.comments || []);
  const [isDeleteDialogBox, setIsDeleteDialogBox] = useState(false);
  const item = isPostPage ? props?.page || props?.createdBy : props?.user;

  useEffect(() => {
    const userLiked = likes.includes(me?._id);
    setIsLiked(userLiked);
  }, [me, likes]);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const likeHandler = async () => {
    try {
      setIsLiked(!isLiked);
      setTotalLike(isLiked ? totalLike - 1 : totalLike + 1);
      if (isPostPage) {
        await onLikePostPage(_id);
      } else {
        await onLikePost(_id);
      }
    } catch (error) {
      throw error;
    }
  };

  const handleShowMessages = () => {
    setIsShowMessages(!isShowMessages);
  };

  const handleSharePostModal = () => {
    setSharePost(!isSharePost);
  };

  const handleToggleDeleteDialogBox = () =>
    setIsDeleteDialogBox(!isDeleteDialogBox);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const init = useCallback(async () => {
    try {
      if (isPostPage) {
        const comments = await onGetCommentsInPostPage(_id);
        setComments(comments);
      } else {
        const comments = await onGetCommentsInPost(_id);
        setComments(comments);
      }
    } catch (error) {
      throw error;
    }
  }, [_id, onGetCommentsInPost, onGetCommentsInPostPage, isPostPage]);

  const handleDeleteSharePost = async () => {
    try {
      if (isPostPage) {
        await onDeletePostPage(_id);
      } else {
        await onDeletePost(_id);
      }
      window.location.reload();
    } catch (error) {
      throw error;
    }
  };

  return (
    <Box>
      <Box>
        <Modal isOpen={isSharePost} onToggleModal={handleSharePostModal}>
          {isSharePost && (
            <CreateSharePostCard
              isPostPage={isPostPage}
              onToggleModal={handleSharePostModal}
              onDone={props.onDone}
              {...props.shareId}
            />
          )}
        </Modal>
      </Box>
      <Card variant="elevation">
        {isPostPage ? (
          <CardHeader
            avatar={
              <Avatar
                sx={{ width: 40, height: 40 }}
                alt={item?.title || item?.username}
                src={item?.pagePicture || item?.profilePicture}
                component={Link}
                underline="none"
                color="inherit"
                href={
                  isPostPage
                    ? `/#/profile/${item?._id}`
                    : `/#/pages/${item?._id}`
                }
              >
                {(item?.title || item?.username).charAt(0).toUpperCase()}
              </Avatar>
            }
            action={
              (isAdmin(me) || isOwner(me, item)) && (
                <IconButton
                  size="medium"
                  aria-label="settings"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleMenu}
                  color="inherit"
                >
                  <MoreVertIcon />
                </IconButton>
              )
            }
            title={item?.title || item?.username}
            subheader={format(createdAt)}
          />
        ) : (
          <CardHeader
            avatar={
              <Avatar
                sx={{ width: 40, height: 40 }}
                alt={item?.username}
                src={item?.profilePicture}
                component={Link}
                underline="none"
                color="inherit"
                href={isLoading ? null : `/#/profile/${item?._id}`}
              >
                {item?.username?.charAt(0).toUpperCase()}
              </Avatar>
            }
            action={
              (isAdmin(me) || isOwner(me, item)) && (
                <IconButton
                  size="medium"
                  aria-label="settings"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleMenu}
                  color="inherit"
                >
                  <MoreVertIcon />
                </IconButton>
              )
            }
            title={item?.username}
            subheader={format(createdAt)}
          />
        )}
        <Menu
          sx={{ mt: "40px" }}
          id="menu-appbar"
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          keepMounted
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem
            onClick={() => {
              handleToggleDeleteDialogBox();
              handleClose();
            }}
          >
            <ListItemIcon>
              <DeleteIcon fontSize="small" />
            </ListItemIcon>
            <Typography variant="body2">ลบ</Typography>
            <DialogBox
              isOpen={isDeleteDialogBox}
              handleClose={handleToggleDeleteDialogBox}
              handleAgree={handleDeleteSharePost}
              title="คุณต้องการลบโพสต์อันนี้ใช่ไหม ?"
              desc="โพสต์ของคุณจะถูกลบโดยถาวร และไม่สามารถกู้คืนได้ในภายหลัง"
            />
          </MenuItem>
        </Menu>
        {message && (
          <CardContent sx={{ pt: 1, pb: 1 }}>
            <Typography variant="body2">{message}</Typography>
          </CardContent>
        )}
        <CardContent>
          <PostCard isShare {...shareId} />
        </CardContent>
        <CardActions sx={{ justifyContent: "space-between" }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <IconButton size="small" onClick={likeHandler}>
              {isLiked ? (
                <FavoriteOutlinedIcon fontSize="medium" color="error" />
              ) : (
                <FavoriteBorderIcon fontSize="medium" />
              )}
            </IconButton>
            <Typography>{totalLike}</Typography>
            <IconButton size="small" onClick={handleShowMessages}>
              {isShowMessages ? (
                <ModeCommentIcon fontSize="medium" />
              ) : (
                <ModeCommentOutlinedIcon fontSize="medium" />
              )}
            </IconButton>
            <Typography>{comments?.length}</Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <IconButton size="small" onClick={handleSharePostModal}>
              <ShareOutlinedIcon fontSize="medium" />
            </IconButton>
          </Box>
        </CardActions>
        {isShowMessages && (
          <CardContent sx={{ pt: 1 }}>
            <Comments
              isPostPage={isPostPage}
              user={item}
              comments={comments}
              onDone={init}
              id={_id}
            />
          </CardContent>
        )}
      </Card>
    </Box>
  );
};

export default SharePostCard;
