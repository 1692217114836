import { Route, Routes, Navigate, useLocation } from "react-router-dom";

import AuthContextProvider, { useAuth } from "./contexts/Auth/Context";
import AppContextProvider from "./contexts/App/Context";

import Home from "./pages/Home";
import Login from "./pages/Login";
import Register from "./pages/Register";
import Packages from "./pages/Packages";
import NotFound from "./pages/NotFound";
import Profile from "./pages/Profile";
import ProfileSettings from "./pages/ProfileSettings";
import Messages from "./pages/Messages";
import Pages from "./pages/Pages";
import PagesSettings from "./pages/PagesSettings";

const ProtectedRoute = ({ children }) => {
  const { token } = useAuth();
  const location = useLocation();
  if (!token) {
    return <Navigate to="/login" replace state={{ from: location }} />;
  }
  return <AppContextProvider>{children}</AppContextProvider>;
};

function App() {
  return (
    <AuthContextProvider>
      <Routes>
        <Route
          index
          element={
            <ProtectedRoute>
              <Home />
            </ProtectedRoute>
          }
        />
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route
          path="/profile/:userId/packages"
          element={
            <ProtectedRoute>
              <Packages />
            </ProtectedRoute>
          }
        />
        <Route
          path="/profile/:userId/messages"
          element={
            <ProtectedRoute>
              <Messages />
            </ProtectedRoute>
          }
        />
        <Route
          path="/profile/:userId"
          element={
            <ProtectedRoute>
              <Profile />
            </ProtectedRoute>
          }
        />
        <Route
          path="/profile/settings"
          element={
            <ProtectedRoute>
              <ProfileSettings />
            </ProtectedRoute>
          }
        />
        <Route
          path="/pages/:pageId"
          element={
            <ProtectedRoute>
              <Pages />
            </ProtectedRoute>
          }
        />
        <Route
          path="/pages/:pageId/settings"
          element={
            <ProtectedRoute>
              <PagesSettings />
            </ProtectedRoute>
          }
        />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </AuthContextProvider>
  );
}

export default App;
