import React from "react";

import Avatar from "@mui/material/Avatar";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardActionArea from "@mui/material/CardActionArea";
import Link from "@mui/material/Link";

const PageCard = (post) => {
  const { _id, title, pagePicture } = post;
  return (
    <Card variant="outlined">
      <CardActionArea
        component={Link}
        underline="none"
        color="inherit"
        href={`/#/pages/${_id}`}
      >
        <CardHeader
          avatar={
            <Avatar
              sx={{ width: 40, height: 40 }}
              alt={title}
              src={pagePicture}
            >
              {title?.charAt(0).toUpperCase()}
            </Avatar>
          }
          title={title}
        />
      </CardActionArea>
    </Card>
  );
};

export default PageCard;
