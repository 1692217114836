import { useState, useEffect } from "react";

import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import CloseIcon from "@mui/icons-material/Close";
import LoadingButton from "@mui/lab/LoadingButton";

import { useApp } from "../../contexts/App/Context";

import Modal from "../Modal";
import UploadFiles from "../UploadFiles";

const BG_IMAGE =
  "https://images.unsplash.com/photo-1618005182384-a83a8bd57fbe?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1064&q=80";

const Background = ({ src, alt, onChange, editable, ...props }) => {
  const { isLoading, onGetFiles } = useApp();
  const [isUpload, setIsUpload] = useState(false);
  const [eventId, setEventId] = useState(null);
  const [files, setFiles] = useState([]);

  const handleModal = () => {
    setIsUpload(!isUpload);
    setFiles([]);
  };

  const handleFiles = (data) => setFiles([...files, ...data]);

  const handleGetFiles = async () => {
    try {
      const data = await onGetFiles(eventId);
      onChange(data[0]?.url);
    } catch (error) {
      throw error;
    } finally {
      handleModal();
    }
  };

  useEffect(() => {
    if (isUpload) {
      setEventId(Date.now());
    }
  }, [isUpload]);

  return (
    <Box sx={{ position: "reletive" }}>
      <Box
        component="img"
        sx={{
          width: "100%",
          maxHeight: { xs: 200, md: 350 },
          objectFit: "cover",
          ...props,
        }}
        alt={alt}
        src={src || BG_IMAGE}
      />
      {editable && (
        <Box
          sx={{
            position: "absolute",
            right: 2,
            bottom: 10,
          }}
        >
          <IconButton onClick={handleModal}>
            <EditIcon />
          </IconButton>
        </Box>
      )}
      <Modal isOpen={isUpload} onToggleModal={handleModal}>
        {isUpload && (
          <Card variant="elevation">
            <CardHeader
              subheader={files.length === 1 && "อัพโหลดสำเร็จแล้ว"}
              action={
                <IconButton
                  aria-label="close"
                  onClick={!isLoading ? handleModal : undefined}
                >
                  <CloseIcon />
                </IconButton>
              }
            />
            <CardContent sx={{ p: 0 }}>
              <UploadFiles
                eventId={eventId}
                title="เพื่มรูปภาพ"
                files={files}
                accept="image/jpeg, image/png"
                handleFiles={handleFiles}
              />
            </CardContent>
            <CardActions sx={{ p: 2 }}>
              <LoadingButton
                type="button"
                loading={isLoading}
                disabled={files.length === 0}
                color="success"
                fullWidth
                variant="contained"
                onClick={handleGetFiles}
              >
                ยืนยัน
              </LoadingButton>
            </CardActions>
          </Card>
        )}
      </Modal>
    </Box>
  );
};

export default Background;
