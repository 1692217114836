import axios from "axios";

const createPayment = async (body, token) => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}/payments`,
      body,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return res.data;
  } catch (error) {
    throw error;
  }
};

export { createPayment };
