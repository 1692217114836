import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";

import PageCard from "../../components/PageCard";
import CreatePageDialog from "../../components/CreatePageDialog";

import { useApp } from "../../contexts/App/Context";
import { isVip, isAdmin } from "../../utils/auth";

const Pages = () => {
  const { pages, onGetPages, me } = useApp();
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    onGetPages();
  }, []);

  const handleModal = () => {
    setIsOpen(!isOpen);
  };

  return (
    <Box>
      {isOpen && <CreatePageDialog isOpen={isOpen} handleModal={handleModal} />}
      <Stack spacing={1}>
        {pages.map((page) => (
          <PageCard key={page?._id} {...page} />
        ))}
        {(isVip(me) || isAdmin(me)) && (
          <Button
            variant="contained"
            startIcon={<AddIcon />}
            onClick={handleModal}
          >
            สร้างเพจใหม่
          </Button>
        )}
      </Stack>
    </Box>
  );
};

export default Pages;
