import { useForm } from "react-hook-form";

import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import Link from "@mui/material/Link";

import { useApp } from "../../contexts/App/Context";
import { isOwner } from "../../utils/auth";

const CreateComment = ({ id, onDone, isPostPage }) => {
  const {
    me,
    isLoading,
    onCreateCommentInPost,
    onCreateCommentInPostPage,
    page,
  } = useApp();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const onSubmit = async (variables) => {
    try {
      if (isPostPage) {
        await onCreateCommentInPostPage(variables, id);
      } else {
        await onCreateCommentInPost(variables, id);
      }
      onDone();
    } catch (error) {
      throw error;
    } finally {
      reset();
    }
  };

  return (
    <Box component="form" noValidate onSubmit={handleSubmit(onSubmit)}>
      <Stack direction="row" spacing={2}>
        {isOwner(me, page?.createdBy) ? (
          <Avatar
            sx={{ width: 34, height: 34 }}
            alt={page?.title}
            src={page?.pagePicture}
            component={Link}
            underline="none"
            color="inherit"
            href={isLoading ? null : `/#/pages/${page?._id}`}
          >
            {page?.title?.charAt(0).toUpperCase()}
          </Avatar>
        ) : (
          <Avatar
            sx={{ width: 34, height: 34 }}
            alt={me?.username}
            src={me?.profilePicture}
            component={Link}
            underline="none"
            color="inherit"
            href={isLoading ? null : `/#/profile/${me?._id}`}
          >
            {me?.username?.charAt(0).toUpperCase()}
          </Avatar>
        )}
        <TextField
          fullWidth
          id="message"
          type="text"
          disabled={isLoading}
          error={errors.message?.type === "maxLength"}
          helperText={errors.message?.message}
          placeholder="เขียนความคิดเห็น..."
          variant="standard"
          {...register("message", {
            required: true,
            maxLength: {
              value: 140,
              message: "ข้อความต้องน้อยกว่า 140 ตัวอักษร",
            },
          })}
        />
      </Stack>
    </Box>
  );
};

export default CreateComment;
