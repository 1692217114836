import { useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import TextField from "@mui/material/TextField";
import LoadingButton from "@mui/lab/LoadingButton";
import Grid from "@mui/material/Grid";

import { useApp } from "../../contexts/App/Context";

import Avatar from "../../components/Avatar";
import Background from "../../components/Background";

const PagesEdit = () => {
  const { isLoading, onUpdatePage, page, onGetPage, onDeletePage } = useApp();
  const {
    register: formPages,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
  } = useForm();
  const params = useParams();
  const navigate = useNavigate();
  const { pageId } = params;

  useEffect(() => {
    if (!page) {
      onGetPage(pageId);
    } else {
      setValue("title", page?.title);
      setValue("description", page?.description);
      setValue("pagePicture", page?.pagePicture);
      setValue("coverPicture", page?.coverPicture);
    }
  }, [page, pageId]);

  const onSubmit = async (variables) => {
    try {
      await onUpdatePage(pageId, variables);
      navigate(`/pages/${pageId}`);
    } catch (error) {
      throw error;
    }
  };

  const handleDeletePage = async () => {
    try {
      await onDeletePage(pageId);
      navigate(`/profile/settings`);
    } catch (error) {
      throw error;
    }
  };

  return (
    <Card variant="outlined">
      <Box component="form" noValidate onSubmit={handleSubmit(onSubmit)}>
        <CardContent>
          <Grid item xs={12} sm={12} md={12}>
            <Box sx={{ position: "relative" }}>
              <Background
                editable
                alt={page?.title}
                src={watch("coverPicture", page?.coverPicture)}
                maxHeight={{ xs: 200, md: 270 }}
                onChange={(url) => setValue("coverPicture", url)}
              />
              <Box
                sx={{
                  position: "absolute",
                  top: { xs: 130, md: 200 },
                  left: "50%",
                  transform: "translateX(-50%)",
                  textAlign: "center",
                }}
              >
                <Avatar
                  width={{ xs: 110, md: 130 }}
                  height={{ xs: 110, md: 130 }}
                  src={watch("pagePicture", page?.pagePicture)}
                  onChange={(url) => setValue("pagePicture", url)}
                >
                  <Typography variant="h3">
                    {page?.title.charAt(0).toUpperCase()}
                  </Typography>
                </Avatar>
              </Box>
            </Box>
            <Box sx={{ height: 75 }} />
            <Box>
              <TextField
                id="title"
                label="ชื่อเพจ"
                variant="standard"
                autoComplete="title"
                required
                disabled={isLoading}
                fullWidth
                error={Boolean(errors.title)}
                helperText={errors.title?.message}
                InputLabelProps={{ shrink: true }}
                {...formPages("title", {
                  required: "ป้อนชื่อเพจ",
                })}
              />
            </Box>
            <Box>
              <TextField
                multiline
                rows={2}
                variant="standard"
                margin="normal"
                fullWidth
                id="description"
                label="คำอธิบาย"
                disabled={isLoading}
                error={Boolean(errors.description)}
                helperText={errors.description?.message}
                InputLabelProps={{ shrink: true }}
                {...formPages("description")}
              />
            </Box>
          </Grid>
        </CardContent>
        <CardActions>
          <LoadingButton
            color="inherit"
            type="button"
            onClick={handleDeletePage}
            fullWidth
            loading={isLoading}
            variant="text"
            sx={{ mb: 1 }}
          >
            ลบ
          </LoadingButton>
          <LoadingButton
            type="submit"
            fullWidth
            loading={isLoading}
            variant="contained"
            sx={{ mb: 1 }}
          >
            บันทึก
          </LoadingButton>
        </CardActions>
      </Box>
    </Card>
  );
};

export default PagesEdit;
