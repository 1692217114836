import React, { useState, Fragment } from "react";

import Avatar from "@mui/material/Avatar";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import Link from "@mui/material/Link";
import ListItemIcon from "@mui/material/ListItemIcon";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import StarsIcon from "@mui/icons-material/Stars";
import ChatBubbleOutlineOutlinedIcon from "@mui/icons-material/ChatBubbleOutlineOutlined";

import { useAuth } from "../../contexts/Auth/Context";
import { useApp } from "../../contexts/App/Context";
import LOGO from "../../assets/logo_only_transparent.png";
import Search from "../Search";

const Narbar = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const { onLogout, isLoading } = useAuth();
  const { me } = useApp();

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const logout = async () => {
    try {
      await onLogout();
    } catch (error) {
      throw error;
    } finally {
      handleClose();
    }
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="fixed" color="inherit">
        <Toolbar>
          <Box
            sx={{
              flexGrow: 1,
              mt: 0.7,
            }}
          >
            <Box href="/#/" component={Link} underline="none">
              <Box
                component="img"
                sx={{
                  width: 50,
                }}
                alt="Mekong native logo"
                src={LOGO}
              />
            </Box>
          </Box>
          <Search />

          {me && (
            <Fragment>
              <IconButton
                size="large"
                aria-label="messages of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                color="inherit"
                href={`/#/profile/${me?._id}/messages`}
                component={Link}
                underline="none"
              >
                <ChatBubbleOutlineOutlinedIcon />
              </IconButton>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleMenu}
                color="inherit"
              >
                <Avatar
                  sx={{ width: 34, height: 34 }}
                  alt={me.username}
                  src={me.profilePicture}
                >
                  {me.username?.charAt(0).toUpperCase()}
                </Avatar>
              </IconButton>
              <Menu
                sx={{ mt: "50px" }}
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <MenuItem
                  onClick={handleClose}
                  href={`/#/profile/${me?._id}/packages`}
                  color="inherit"
                  underline="none"
                  disabled={isLoading}
                  component={Link}
                >
                  <ListItemIcon>
                    <StarsIcon fontSize="small" color="warning" />
                  </ListItemIcon>
                  <Typography variant="body2">ปลดล็อค</Typography>
                </MenuItem>
                <Divider />
                <MenuItem
                  onClick={handleClose}
                  href={`/#/profile/${me?._id}`}
                  color="inherit"
                  underline="none"
                  disabled={isLoading}
                  component={Link}
                >
                  <Typography variant="body2">ดูโปรไฟล์</Typography>
                </MenuItem>
                <MenuItem
                  onClick={handleClose}
                  href={`/#/profile/settings`}
                  color="inherit"
                  underline="none"
                  disabled={isLoading}
                  component={Link}
                >
                  <Typography variant="body2">การตั้งค่า</Typography>
                </MenuItem>
                <Divider />
                <MenuItem onClick={logout} disabled={isLoading}>
                  <Typography variant="body2">ออกจากระบบ</Typography>
                </MenuItem>
              </Menu>
            </Fragment>
          )}
        </Toolbar>
      </AppBar>
    </Box>
  );
};

export default Narbar;
