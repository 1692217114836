import React from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";

import MemberCard from "../../components/MemberCard";

import { useApp } from "../../contexts/App/Context";

const Members = () => {
  const { page } = useApp();

  return (
    <Box>
      <Stack spacing={1}>
        {page?.waitToFollow?.map((user) => (
          <MemberCard isWait key={user?._id} {...user} />
        ))}
        {page?.followers?.map((user) => (
          <MemberCard key={user?._id} {...user} />
        ))}
      </Stack>
    </Box>
  );
};

export default Members;
