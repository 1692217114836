import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Toolbar from "@mui/material/Toolbar";
// import Typography from "@mui/material/Typography";
// import Container from "@mui/material/Container";
import Navbar from "../../components/Navbar";
import Timeline from "../../containers/Timeline";

const Home = () => {
  return (
    <Box>
      <Navbar />
      <Toolbar />
      <Box>
        <Grid container spacing={{ xs: 0, sm: 0, md: 3 }}>
          <Grid item xs={0} sm={0} md={2}>
            <Box
              sx={{
                display: { xs: "none", sm: "none", md: "block" },
              }}
            >
              {/* <Container>
                <Box sx={{ bgcolor: "green", height: "100vh" }}>
                  <Typography variant="h5">Chat</Typography>
                </Box>
              </Container> */}
            </Box>
          </Grid>
          <Grid item xs={12} sm={8} md={8}>
            <Box
              sx={{
                display: { xs: "block", sm: "block", md: "block" },
              }}
            >
              <Timeline />
            </Box>
          </Grid>
          <Grid item xs={0} sm={4} md={2}>
            <Box
              sx={{
                display: { xs: "none", sm: "block", md: "block" },
              }}
            >
              {/* <Container>
                <Box sx={{ bgcolor: "green", height: "100vh" }}>
                  <Typography variant="h5">Chat</Typography>
                </Box>
              </Container> */}
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default Home;
