import React from "react";
import { useForm } from "react-hook-form";

import LoadingButton from "@mui/lab/LoadingButton";
import TextField from "@mui/material/TextField";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";
import Link from "@mui/material/Link";

import { useAuth } from "../../contexts/Auth/Context";
import LOGO from "../../assets/logo_transparent.png";

export default function Register() {
  const { onRegister, isLoading } = useAuth();
  const {
    register: formRegister,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const onSubmit = async (variables) => {
    try {
      await onRegister(variables);
    } catch (error) {
      throw error;
    } finally {
      reset();
    }
  };

  return (
    <Grid container component="main" sx={{ height: "100vh" }}>
      <Grid
        item
        xs={false}
        sm={4}
        md={7}
        sx={{
          backgroundImage: "url(https://source.unsplash.com/random)",
          backgroundRepeat: "no-repeat",
          backgroundColor: (t) =>
            t.palette.mode === "light"
              ? t.palette.grey[50]
              : t.palette.grey[900],
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      />
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <Box
          sx={{
            my: 8,
            mx: 4,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Box
            component="img"
            sx={{
              // height: 233,
              width: 220,
              // maxHeight: { xs: 233, md: 167 },
              // maxWidth: { xs: 350, md: 250 },
            }}
            alt="Mekong native logo"
            src={LOGO}
          />
          <Box
            component="form"
            noValidate
            onSubmit={handleSubmit(onSubmit)}
            sx={{ mt: 3 }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  id="username"
                  label="ชื่อเล่น"
                  autoComplete="username"
                  required
                  disabled={isLoading}
                  fullWidth
                  error={Boolean(errors.username)}
                  helperText={errors.username?.message}
                  {...formRegister("username", {
                    required: "ป้อนชื่อเล่น",
                    minLength: {
                      value: 3,
                      message: "ชื่อเล่นต้องมากกว่า 6 ตัวขึ้นไป",
                    },
                    maxLength: {
                      value: 30,
                      message: "ชื่อเล่นต้องน้อยกว่า 30 ตัว",
                    },
                  })}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="email"
                  label="อีเมล"
                  disabled={isLoading}
                  autoComplete="email"
                  error={Boolean(errors.email)}
                  helperText={errors.email?.message}
                  {...formRegister("email", {
                    required: "ป้อนอีเมล",
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                      message: "รูปแบบอีเมลไม่ถูกต้อง",
                    },
                  })}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  label="รหัสผ่าน"
                  type="password"
                  id="password"
                  disabled={isLoading}
                  error={Boolean(errors.password)}
                  helperText={errors.password?.message}
                  autoComplete="new-password"
                  {...formRegister("password", {
                    required: "ป้อนรหัสผ่าน",
                    minLength: {
                      value: 6,
                      message: "รหัสผ่านต้องมากกว่า 6 ตัวขึ้นไป",
                    },
                    maxLength: {
                      value: 30,
                      message: "รหัสผ่านต้องน้อยกว่า 30 ตัว",
                    },
                  })}
                />
              </Grid>
            </Grid>

            <LoadingButton
              type="submit"
              fullWidth
              loading={isLoading}
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              ถัดไป
            </LoadingButton>
            <Divider />
            <Button
              component={Link}
              underline="none"
              href={"/#/login"}
              disabled={isLoading}
              variant="outlined"
              fullWidth
              sx={{ mt: 2, mb: 2 }}
            >
              มีบัญชีอยู่แล้ว
            </Button>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
}
