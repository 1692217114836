import React, { useEffect } from "react";
import { useParams } from "react-router-dom";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Avatar from "@mui/material/Avatar";
import LoadingButton from "@mui/lab/LoadingButton";
import Stack from "@mui/material/Stack";
import SettingsIcon from "@mui/icons-material/Settings";
import Link from "@mui/material/Link";
import Toolbar from "@mui/material/Toolbar";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import InfoIcon from "@mui/icons-material/Info";

import Navbar from "../../components/Navbar";
import Background from "../../components/Background";
import InfoCard from "../../containers/InfoCard";
import PostsPages from "../../containers/PostsPages";
import { useApp } from "../../contexts/App/Context";
import { isOwner } from "../../utils/auth";

const Pages = () => {
  const {
    page,
    me,
    isLoading,
    onFollowPage,
    onUnfollowPage,
    onGetPostsPages,
    onGetPageAndPosts,
  } = useApp();
  const params = useParams();
  const { pageId } = params;
  const isMyPage = isOwner(me, page?.createdBy);
  const isFollowers = page?.followers?.some((user) => user._id === me?._id);
  const isWaitToFollow = page?.waitToFollow?.some(
    (user) => user._id === me?._id
  );

  const lists = [
    {
      icon: <InfoIcon fontSize="small" />,
      text: page?.description,
    },
    {
      icon: <PeopleAltIcon fontSize="small" />,
      text: `ผู้ติดตาม ${page?.followers.length || 0} คน`,
    },
  ];

  useEffect(() => {
    onGetPageAndPosts(pageId, { limit: 5, skip: 0 });
  }, [pageId]);

  const init = async () => {
    try {
      await onGetPostsPages({ limit: 5, skip: 0, pageId });
    } catch (error) {
      throw error;
    }
  };

  const handleFollow = async () => {
    try {
      if (isFollowers || isWaitToFollow) {
        // User wanna unfollow page and User cancel follow this page
        await onUnfollowPage(pageId);
      } else {
        // User wanna follow page
        await onFollowPage(pageId);
      }
    } catch (error) {
      throw error;
    }
  };

  return (
    <Box>
      <Navbar />
      <Toolbar />
      <Background alt={page?.title} src={page?.coverPicture} />
      <Box
        sx={{
          position: "absolute",
          top: { xs: 200, md: 340 },
          left: "50%",
          transform: "translateX(-50%)",
          textAlign: "center",
        }}
      >
        <Avatar
          alt={page?.title}
          src={page?.pagePicture}
          sx={{
            width: { xs: 120, md: 150 },
            height: { xs: 120, md: 150 },
            border: "4px solid white",
          }}
        >
          <Typography variant="h3">
            {page?.title?.charAt(0).toUpperCase()}
          </Typography>
        </Avatar>
        <Typography variant="h5" sx={{ mt: 1 }}>
          {page?.title}
        </Typography>
      </Box>
      <Box
        sx={{
          position: "relative",
          top: { xs: 120, md: 140 },
        }}
      >
        <Container maxWidth="md">
          <Stack
            direction="row"
            spacing={2}
            justifyContent="flex-end"
            alignItems="center"
            sx={{ mb: 3 }}
          >
            {isMyPage ? (
              <Box>
                <LoadingButton
                  component={Link}
                  underline="none"
                  href={`/#/pages/${pageId}/settings`}
                  startIcon={<SettingsIcon />}
                  loading={isLoading}
                  variant="contained"
                >
                  การตั้งค่า
                </LoadingButton>
              </Box>
            ) : (
              <Box>
                <LoadingButton
                  type="button"
                  loading={isLoading}
                  variant={isFollowers ? "outlined" : "contained"}
                  onClick={handleFollow}
                  color="inherit"
                >
                  {isFollowers
                    ? "เลิกติดตาม"
                    : isWaitToFollow
                    ? "ยกเลิกคำขอ"
                    : "ติดตาม"}
                </LoadingButton>
              </Box>
            )}
          </Stack>
          <Grid container spacing={{ xs: 2, sm: 2, md: 2 }}>
            <Grid item xs={12} sm={12} md={6}>
              <InfoCard title="เกี่ยวกับ" lists={lists} />
            </Grid>
            {(isMyPage || isFollowers) && (
              <Grid item xs={12} sm={12} md={6}>
                <PostsPages onDone={init} />
              </Grid>
            )}
          </Grid>
        </Container>
      </Box>
    </Box>
  );
};

export default Pages;
