import axios from "axios";

const getConversation = async (token) => {
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}/conversations`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return res?.data;
  } catch (error) {
    throw error;
  }
};

const createConversation = async (body, token) => {
  try {
    await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}/conversations`,
      body,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  } catch (error) {
    throw error;
  }
};

export { getConversation, createConversation };
