import axios from "axios";

const me = async (token) => {
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}/users/me`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (!res.data?._id) throw res.data;
    return res.data;
  } catch (error) {
    if (error.message === "Request failed with status code 401") {
      window.localStorage.removeItem("token");
      window.location.reload();
    } else {
      throw new Error(error.message);
    }
  }
};

const searchUsers = async (query, token) => {
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}/users/search?${query}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return res.data;
  } catch (error) {
    throw error;
  }
};

const updateMe = async (payload, token) => {
  try {
    const res = await axios.put(
      `${process.env.REACT_APP_BACKEND_URL}/users/me`,
      payload,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (!res.data?._id) throw res.data;
    return res.data;
  } catch (error) {
    throw error;
  }
};

const user = async (userId) => {
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}/users/${userId}`
    );
    if (!res.data?._id) throw res.data;
    return res.data;
  } catch (error) {
    throw error;
  }
};

const followUser = async (followingId, token) => {
  try {
    const res = await axios.put(
      `${process.env.REACT_APP_BACKEND_URL}/users/${followingId}/follow`,
      null,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (!res.data?._id) throw res.data;
    return res.data;
  } catch (error) {
    throw error;
  }
};

const unfollowUser = async (followingId, token) => {
  try {
    const res = await axios.put(
      `${process.env.REACT_APP_BACKEND_URL}/users/${followingId}/unfollow`,
      null,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (!res.data?._id) throw res.data;
    return res.data;
  } catch (error) {
    throw error;
  }
};

const deleteUser = async (userId, token) => {
  try {
    const res = await axios.delete(
      `${process.env.REACT_APP_BACKEND_URL}/users/${userId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return res?.data;
  } catch (error) {
    throw error;
  }
};

export {
  me,
  user,
  followUser,
  unfollowUser,
  updateMe,
  searchUsers,
  deleteUser,
};
