import React from "react";
import { useForm } from "react-hook-form";

import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import SendIcon from "@mui/icons-material/Send";

import { isOwner } from "../../utils/auth";

import { useApp } from "../../contexts/App/Context";

const MessageForm = ({ ws = {} }) => {
  const { register, handleSubmit, reset } = useForm();
  const { onCreateMessage, conversationId, conversations, me } = useApp();

  const onSubmit = async ({ message }) => {
    try {
      message = message.trim();
      const conversation = conversations.find(
        ({ _id }) => conversationId === _id
      );
      if (message && conversation) {
        const receiver = conversation.members.find(
          ({ _id }) => !isOwner(me, { _id })
        );
        ws.sendJsonMessage({
          action: "sendmessage",
          message,
          receiver: receiver?._id,
          sender: me?._id,
        });
        onCreateMessage({ message, conversationId });
      } else {
        console.error({ message, conversation });
      }
    } catch (error) {
      throw error;
    } finally {
      reset();
    }
  };

  return (
    <Box
      component="form"
      noValidate
      autoComplete="off"
      onSubmit={handleSubmit(onSubmit)}
    >
      <AppBar position="fixed" color="inherit" sx={{ top: "auto", bottom: 0 }}>
        <Toolbar>
          <OutlinedInput
            id="message-form"
            placeholder="Aa"
            variant="outlined"
            fullWidth
            size="small"
            endAdornment={
              <InputAdornment position="end">
                <IconButton aria-label="send-message" type="submit" edge="end">
                  <SendIcon />
                </IconButton>
              </InputAdornment>
            }
            {...register("message")}
          />
        </Toolbar>
      </AppBar>
    </Box>
  );
};

export default MessageForm;
